export const clearStorage = () => {
  localStorage.clear();
  sessionStorage.clear();
};

export const cities = [
  "Athens",
  "Thessaloniki",
  "Patras",
  "Heraklion (Crete)",
  "Larissa",
  "Volos",
  "Ioannina",
  "Rhodes",
  "Chania (Crete)",
];

type CityRegions = {
  [city: string]: { label: string; value: string }[];
};

export const cityRegions: CityRegions = {
  Athens: [
    { label: "Plaka", value: "plaka" },
    { label: "Monastiraki", value: "monastiraki" },
    { label: "Kolonaki", value: "kolonaki" },
    { label: "Glyfada", value: "glyfada" },
    { label: "Marousi", value: "marousi" },
    { label: "Kifisia", value: "kifisia" },
    { label: "Piraeus", value: "piraeus" },
    { label: "Nea Smyrni", value: "nea_smyrni" },
    { label: "Exarchia", value: "exarchia" },
  ],
  Thessaloniki: [
    { label: "Ano Poli", value: "ano_poli" },
    { label: "Ladadika", value: "ladadika" },
    { label: "Toumba", value: "toumba" },
    { label: "Kalamaria", value: "kalamaria" },
    { label: "Pylaia", value: "pylaia" },
    { label: "Charilaou", value: "charilaou" },
    { label: "Efkarpia", value: "efkarpia" },
    { label: "Neapoli", value: "neapoli" },
    { label: "Triandria", value: "triandria" },
  ],
  Patras: [
    { label: "Psila Alonia", value: "psila_alonia" },
    { label: "Rio", value: "rio" },
    { label: "Agia Sofia", value: "agia_sofia" },
    { label: "Ano Poli", value: "ano_poli" },
    { label: "Skagiopouleio", value: "skagiopouleio" },
    { label: "Kato Achagia", value: "kato_achagia" },
    { label: "Zarouhla", value: "zarouhla" },
    { label: "Sevasti", value: "sevasti" },
    { label: "Perivola", value: "perivola" },
  ],
  "Heraklion (Crete)": [
    { label: "Knossos", value: "knossos" },
    { label: "Koroni Magara", value: "koroni_magara" },
    { label: "Therissos", value: "therissos" },
    { label: "Poros", value: "poros" },
    { label: "Nea Alikarnassos", value: "nea_alikarnassos" },
    { label: "Mesampelies", value: "mesampelies" },
    { label: "Agios Ioannis", value: "agios_ioannis" },
    { label: "Katsampas", value: "katsampas" },
    { label: "Voutes", value: "voutes" },
  ],
  Larissa: [
    { label: "Nees Kalyves", value: "nees_kalyves" },
    { label: "Alkazar", value: "alkazar" },
    { label: "Giannouli", value: "giannouli" },
    { label: "Ampelokipoi", value: "ampelokipoi" },
    { label: "Neapolis", value: "neapolis" },
    { label: "Stathmos", value: "stathmos" },
    { label: "Falani", value: "falani" },
    { label: "Agios Thomas", value: "agios_thomas" },
    { label: "Koulouri", value: "koulouri" },
  ],
  Volos: [
    { label: "Agria", value: "agria" },
    { label: "Nea Ionia", value: "nea_ionia" },
    { label: "Ano Volos", value: "ano_volos" },
    { label: "Portaria", value: "portaria" },
    { label: "Makrinitsa", value: "makrinitsa" },
    { label: "Palia", value: "palia" },
    { label: "Pelion", value: "pelion" },
    { label: "Dimini", value: "dimini" },
    { label: "Chrysi Akti", value: "chrysi_akti" },
  ],
  Ioannina: [
    { label: "Perama", value: "perama" },
    { label: "Neochoropoulo", value: "neochoropoulo" },
    { label: "Anatoli", value: "anatoli" },
    { label: "Katsikas", value: "katsikas" },
    { label: "Dodoni", value: "dodoni" },
    { label: "Amfithea", value: "amfithea" },
    { label: "Ligiades", value: "ligiades" },
    { label: "Bizani", value: "bizani" },
    { label: "Kastritsa", value: "kastritsa" },
  ],
  Rhodes: [
    { label: "Old Town", value: "old_town" },
    { label: "Ialyssos", value: "ialyssos" },
    { label: "Lindos", value: "lindos" },
    { label: "Faliraki", value: "faliraki" },
    { label: "Kolymbia", value: "kolymbia" },
    { label: "Afandou", value: "afandou" },
    { label: "Kremasti", value: "kremasti" },
    { label: "Archangelos", value: "archangelos" },
    { label: "Koskinou", value: "koskinou" },
  ],
  "Chania (Crete)": [
    { label: "Old Town", value: "old_town" },
    { label: "Nea Chora", value: "nea_chora" },
    { label: "Splantzia", value: "splantzia" },
    { label: "Halepa", value: "halepa" },
    { label: "Agia Marina", value: "agia_marina" },
    { label: "Kounoupidiana", value: "kounoupidiana" },
    { label: "Daratsos", value: "daratsos" },
    { label: "Souda", value: "souda" },
    { label: "Platanias", value: "platanias" },
  ],
};
