import { useState } from "react";
import { useDropzone } from "react-dropzone";
import { AddImage } from "../../icons/addImage";

export const Upload = ({
  maxFiles,
  handleDrop,
}: {
  maxFiles: number;
  handleDrop: (value: File[]) => void;
}) => {
  const [uploadedFiles, setUploadedFiles] = useState<File[]>([]);

  const onDrop = (acceptedFiles: File[]) => {
    setTimeout(() => {
      setUploadedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]); // Append new files
      handleDrop([...uploadedFiles, ...acceptedFiles]); // Pass updated list to parent
      console.log("[FILES]:", [...uploadedFiles, ...acceptedFiles]);
    }, 10000); // 10 seconds delay
  };

  const { getRootProps, getInputProps } = useDropzone({
    accept: { "image/*": [] },
    onDrop,
    maxFiles: maxFiles,
  });

  return (
    <div>
      <div
        {...getRootProps()}
        className="flex items-center justify-center shadow-lg p-4 w-32 h-32 rounded-sm cursor-pointer m-2"
      >
        <input {...getInputProps()} />
        <AddImage />
      </div>

       {/* Uploaded Photos Display */}
       {uploadedFiles.length > 0 && (
        <div className="mt-2 text-center">
          <p className="text-sm font-medium text-green-600">
            {uploadedFiles.length} {uploadedFiles.length === 1 ? "photo" : "photos"} uploaded
          </p>
          <div className="grid grid-cols-3 gap-2 mt-2">
            {uploadedFiles.map((file, index) => (
              <img
                key={index}
                src={URL.createObjectURL(file)}
                alt={`Uploaded ${index + 1}`}
                className="w-20 h-20 object-cover rounded-md shadow-md"
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
