import { Check } from "lucide-react";

export const Stepper = ({
  totalSteps,
  currentStep,
  onStepClick,
}: {
  totalSteps: number;
  currentStep: number;
  onStepClick?: (step: number) => void;
}) => {
  return (
    <div className="flex items-center justify-center">
      {Array.from({ length: totalSteps }, (_, index) => {
        const step = index + 1;
        const isActive = step === currentStep;
        const isCompleted = step < currentStep;
        const isClickable = step <= currentStep; // Prevent skipping steps

        return (
          <div key={step} className="flex items-center">
            {/* Step Button */}
            <button
              onClick={() => isClickable && onStepClick && onStepClick(step)}
              disabled={!isClickable}
              className={`flex items-center justify-center w-10 h-10 rounded-full border-2 transition-all
                ${isCompleted ? "border-[#F85A20] bg-[#F85A20] text-white" : ""}
                ${isActive ? "border-[#F85A20] bg-[#F85A20] text-white" : ""}
                ${!isCompleted && !isActive ? "border-gray-300 bg-white text-gray-500" : ""}
                ${isClickable ? "cursor-pointer hover:bg-[#FFC000]" : "cursor-not-allowed"}
              `}
            >
              {isCompleted ? <Check className="text-white" /> : step}
            </button>

            {/* Connecting Line */}
            {step < totalSteps && (
              <div
                className={`h-1 w-16 flex-1 transition-all ${
                  isCompleted ? "bg-[#F85A20]" : "bg-gray-300"
                }`}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
