import clsx from "clsx";
import { Formik, Form, Field, useFormikContext, FieldArray, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useGetUserInfo } from "../../api/hooks/user";
import { useState } from "react";
import { HouseInfo } from "../../../types/houseInfo";
import { useGetHouseInfo } from "../../api/hooks/useHouse";
import { Upload } from "../ui/upload";
import { Check,Tv, Wifi, Bed, Home, Leaf } from "lucide-react";





const validationSchema = Yup.object().shape({
    surface_area: Yup.number()
      .when("add_house", {
        is: true,
        then: (schema) => schema.required("Surface area is required"),
      })
      .positive("Must be a positive number"),
    city: Yup.string().when("add_house", {
      is: true,
      then: (schema) => schema.required("City is required"),
    }),
    address_line: Yup.string().when("add_house", {
      is: true,
      then: (schema) => schema.required("Address is required"),
    }),
    // image_list: Yup.array().of(Yup.number()).optional(),
    desired_rent: Yup.number()
    .when("add_house", {
      is: true,
      then: (schema) => schema.required("Surface area is required"),
    }),
    roomAmenities: Yup.array().of(Yup.string()).when("add_house", {
      is: true,
      then: (schema) => schema.required("Address is required"),
    }),
    propertyAmenities: Yup.array().of(Yup.string()).when("add_house", {
      is: true,
      then: (schema) => schema.required("Address is required"),
    }),
    flatRules: Yup.array().of(Yup.string()).when("add_house", {
      is: true,
      then: (schema) => schema.required("Address is required"),
    }),
  });

export const FormStepFour = ({
  handleSubmit,
  handleBack,
}: {
  handleSubmit: (values: HouseInfo | null) => void;
  handleBack: () => void;
}) => {
  const { data: userData, isLoading: userInfoLoading } = useGetUserInfo();
//   const {data: houseData, isLoading: houseInfoLoading} = useGetHouseInfo();
  const [addHouse, setAddHouse] = useState(false);

  const initialValues = {
    surface_area: 0,
    city: "",
    address_line: "",
    image_list: [] as File[],
    desired_rent:0,
    floor: 0,
    room_description: "",
    pets_allowed: false,
    smoking_allowed: false,
    property_amenities: [],
    amenities: [],
    estimated_monthly_utilities: 0,
    availability_from: "",
  };

  const roomOptions = {
    // roomAmenities: [
    //   "Furnished", "Private Bathroom", "Balcony", "Natural Light", "Working Space", "Closet", "Single Bed"," Double Bed"
    // ],
    roomAmenities: [
      { value: "Furnished", icon: <Bed size={24} className="text-gray-500" /> },
      { value: "Private Bathroom", icon: <Home size={24} className="text-gray-500" /> },
      { value: "Balcony", icon: <Leaf size={24} className="text-gray-500" /> },
    ],
    // propertyAmenities: [
    //   "TV", "Wifi", "Air Conditioning", "Heating", "Public Transport", "Washing Machine", "Furnished", "Dishwasher", "Kitchen", "Parking", "Balcony", "Garden", "Toilet", "Bathroom", "Natural Light", "View", "Lift", "Sofa", "Sofa Bed"
    // ],
    propertyAmenities: [
      { value: "TV", icon: <Tv size={24} className="text-gray-500" /> },
      { value: "Wifi", icon: <Wifi size={24} className="text-gray-500" /> },
    ],
    flatRules: [
      { value: "Pets" },
      { value: "Smoking" },
    ],
  }

  interface InputFieldProps {
    label: string;
    name: string;
    placeholder: string;
    type?: string;
  }
  
  const InputField: React.FC<InputFieldProps> = ({ label, name, placeholder, type = "text" }) => (
    <div className="max-w-sm">
      <label htmlFor={name} className="block font-medium">
        {label}
      </label>
      <Field
        name={name}
        type={type}
        placeholder={placeholder}
        className="border p-2 rounded w-full"
      />
    </div>
  );


  interface CategorySelectorProps {
    name: string;
    label: string;
    options: { value: string; icon?: JSX.Element }[];
  }
  
  const CategorySelector: React.FC<CategorySelectorProps> = ({ name, label, options }) => {
    const { values, setFieldValue } = useFormikContext<{ [key: string]: any }>();
  
    return (
      <div>
        <label className="block font-medium text-lg">{label}</label>
        <div className="grid grid-cols-3 sm:grid-cols-4 gap-4 mt-3">
          {options.map(({ value, icon }) => {
            const isBooleanField = value === "Pets" || value === "Smoking";
            const fieldName = value === "Pets" ? "pets_allowed" : "smoking_allowed";
            const isSelected = isBooleanField ? values[fieldName] : values[name]?.includes(value);
  
            return (
              <button
                key={value}
                type="button"
                className={clsx(
                  "flex flex-col items-center p-3 rounded-lg transition border",
                  isSelected ? "border-green-500 bg-green-100" : "border-gray-300"
                )}
                onClick={() => {
                  if (isBooleanField) {
                    setFieldValue(fieldName, !values[fieldName]); // Toggle boolean
                  } else {
                    if (isSelected) {
                      setFieldValue(name, values[name].filter((item: string) => item !== value));
                    } else {
                      setFieldValue(name, [...values[name], value]);
                    }
                  }
                }}
              >
                <div className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-400">
                  {icon || <Check size={24} className="text-gray-500" />}
                </div>
                <p className="text-sm mt-2">{value}</p>
              </button>
            );
          })}
        </div>
      </div>
    );
  };
  

  return (
 <div className="flex flex-col gap-4 mx-auto w-8/12">
      {/* Question: Do you want to add a house? */}
      {!addHouse && (
        <div className="text-center p-4 bg-gray-100 rounded-lg">
          <p className="text-lg font-medium">Would you like to add a house?</p>
          <div className="flex justify-center gap-4 mt-3">
            <button
              onClick={() => setAddHouse(true)}
              className="bg-green-500 text-white px-6 py-2 rounded-lg"
            >
              Yes
            </button>
            <button
              onClick={() => handleSubmit(null)}
              className="bg-gray-500 text-white px-6 py-2 rounded-lg"
            >
              No, Finish Form
            </button>
            <button
                  type="button"
                  onClick={handleBack}
                  className={clsx(
                    "bg-gray-500 text-white px-6 py-2 rounded-lg text-white cursor-pointer"
                  )}
                >
                  Back
            </button>
          </div>
        </div>
      )}

      {/* House Form */}
      {addHouse && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid, setFieldValue }) => (
            <Form className="space-y-4 p-4">
            <InputField label="Room Description" name="room_description" placeholder="Enter room description..." />
            <InputField label="City" name="city" placeholder="Enter city..."/>
            <InputField label="Address" name="address_line" placeholder="Enter house address..." />            
            <InputField label="Desired Rent (in euros)" name="desired_rent" placeholder="Enter desired rent..." type="number" />
            <InputField label="Estimated Monthly Utilities (in euros)" name="estimated_monthly_utilities" placeholder="Enter estimated monthly utilities..." type="number" />
            <InputField label="Surface Area (in square meters)" name="surface_area" placeholder="Enter surface area..." type="number"/>            
            <InputField label="Floor" name="floor" placeholder="Enter room's floor..." type="number" />
            <CategorySelector name="amenities" label="Room Amenities" options={roomOptions.roomAmenities} />
            <CategorySelector name="property_amenities" label="Property Amenities" options={roomOptions.propertyAmenities} />
            <CategorySelector name="flat_rules" label="Flat Rules" options={roomOptions.flatRules} />
            <InputField label="Availability From" name="availability_from" placeholder="Enter availability date..." type="date" />

              {/* Image Upload */}
              <div className=" w-full shadow-lg rounded-sm p-4 max-h-96 sm:w-[40%]">
                <div className="">
                    <p className="text-lg font-medium">Add house photos</p>
                    <div className="p-2 rounded-xl" style={{ backgroundColor: 'rgba(255, 192, 0, 0.3)' }}>
                      <p className="text-sm text-gray-500">For better matches add at least a photo of the Bedroom, Living room, Kitchen, Bathroom</p>
                    </div>
                    <Upload maxFiles={10} handleDrop={(files) => { setFieldValue("image_list", files);}}/>
                </div>
              </div>

              {/* Submit & Back Buttons */}
              <div className="flex p-4 justify-end gap-4">
                <button
                  type="button"
                  onClick={handleBack}
                  className={clsx(
                    "bg-gray-300 text-white px-8 py-2 rounded-lg text-lg cursor-pointer sm:px-16 sm:py-4"
                  )}
                >
                  Back
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting || !isValid}
                  className={clsx(
                    "bg-[#32936F] text-white px-8 py-2 rounded-lg text-lg cursor-pointer sm:px-16 sm:py-4",
                    (isSubmitting || !isValid) && "opacity-70"
                  )}
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
