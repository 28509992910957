import { MapPin, Wallet, PencilRuler } from "lucide-react";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import { HouseCardInfo } from "../../../types/houseCardInfo";
import { HouseCard } from "./houseCard";

export const HouseListCard = ({
  cardInfo,
}: {
  cardInfo: HouseCardInfo;
}) => {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="rounded-md shadow-md p-4 flex flex-col gap-2 items-center w-fit hover:cursor-pointer gap-4">
          <div className="flex justify-center items-center w-[300px] h-[300px] overflow-hidden border-[1px] border-[#ddd]">
            <img className="w-full h-full object-cover" src={cardInfo.image_list[0]}
            onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                    e.currentTarget.src = "default-house.png";
                  }}></img>
          </div>
          <div className="w-full flex justify-between gap-4">
            <div className="flex  items-center gap-2">
              <MapPin className="fill-white text-yellow" />
              <p>{cardInfo.city}</p>    
            </div>
            <div className="flex items-center gap-1">
                <PencilRuler className="fill-white text-yellow"></PencilRuler>
                <p>{cardInfo.surface_area} cm<sup>2</sup></p>
            </div>
            <div className="flex items-center gap-1">
              <Wallet className="fill-white text-yellow" />
              <p>{cardInfo.desired_rent}€</p>
            </div>
          </div>
        </div>
      </DialogTrigger>
      <DialogContent>
        <HouseCard cardInfo={cardInfo}/>
      </DialogContent>
    </Dialog>
  );
};
