import { useEffect, useState } from "react";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { DialogHeader, DialogTitle } from "../ui/dialog";
import { Check } from "lucide-react";
import { useGetHouseInfo } from "../../api/hooks/useHouse";
import { HouseCardInfo } from "../../../types/houseCardInfo";
import { CardInfo } from "../../../types/cardInfo";
import { useGetSingleCandidateProfile } from "../../api/hooks/useCandidates";
import { Male } from "../../icons/male";
import { Female } from "../../icons/female";
import { Other } from "../../icons/other";
import { Work } from "../../icons/work";
import { Study } from "../../icons/study";

export const HouseCard = ({
  cardInfo,
}: {
  cardInfo: HouseCardInfo;
})  => {
  // const [candidateInfo, setCandidateInfo] = useState<HouseCardInfo>();
  // const { data } = useGetHouseInfo({house_id: cardInfo.house_id});

  // useEffect(() => {
  //   if (data) {
  //     setCandidateInfo(data);
  //   }
  // }, [data]);

  const [user, setCandidateInfo] = useState<CardInfo>();
  const { data } = useGetSingleCandidateProfile({ user_id: cardInfo.owner });

  useEffect(() => {
    if (data) {
      setCandidateInfo(data);
    }
  }, [data]);

  interface PhotoCollageProps {
    images: string[];
  }

  const PhotoCollage: React.FC<PhotoCollageProps> = ({ images }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    if (images.length === 0) return null;
  
    const maxVisibleImages = 5; // Show at most 5 images
    const remainingCount = images.length - maxVisibleImages;
  
    return (
      <div className="flex gap-2 p-5 max-h-[500px] rounded-[2rem] ">
        {/* Large Image on Left */}
        <div className="flex-1">
          <img
            className="w-full h-full object-cover rounded-lg"
            src={images[0]}
            alt="Main Profile Image"
            onError={(e) => (e.currentTarget.src = "default-house.png")}
          />
        </div>
  
        {/* Grid of Smaller Images on Right */}
        <div className="flex-1 grid grid-rows-2 grid-cols-2 gap-2 relative">
          {images.slice(1, maxVisibleImages - 1).map((src, index) => (
            <img
              key={index}
              className="w-full h-full object-cover rounded-lg"
              src={src}
              alt={`Profile Image ${index + 1}`}
              onError={(e) => (e.currentTarget.src = "default-house.png")}
            />
          ))}
  
          {/* Last Image (with "+X" overlay if there are more images) */}
          {images.length > maxVisibleImages ? (
            <div
              className="relative w-full h-full cursor-pointer"
              onClick={() => setIsModalOpen(true)}
            >
              <img
                className="w-full h-full object-cover rounded-lg brightness-50"
                src={images[maxVisibleImages - 1]}
                alt="More Images"
                onError={(e) => (e.currentTarget.src = "default-house.png")}
              />
              <div className="absolute inset-0 flex items-center justify-center text-white text-2xl font-bold bg-black bg-opacity-50 rounded-lg">
                +{remainingCount}
              </div>
            </div>
          ) : (
            // Normal last image if no extra images
            <img
              className="w-full h-full object-cover rounded-lg"
              src={images[maxVisibleImages - 1]}
              alt="Profile Image"
              onError={(e) => (e.currentTarget.src = "default-house.png")}
            />
          )}
        </div>
  
        {/* Modal to Show All Images */}
        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center p-5">
            <div className="bg-white p-5 rounded-lg max-w-3xl w-full">
              <button
                className="absolute top-4 right-4 text-white text-2xl"
                onClick={() => setIsModalOpen(false)}
              >
                ✕
              </button>
              <div className="grid grid-cols-3 gap-2">
                {images.map((src, index) => (
                  <img
                    key={index}
                    className="w-full h-full object-cover rounded-lg"
                    src={src}
                    alt={`Image ${index + 1}`}
                    onError={(e) => (e.currentTarget.src = "default-house.png")}
                  />
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  interface CategorySelectorProps {
    options: { value: string; icon?: JSX.Element }[];
  }
  
  const CategorySelector: React.FC<CategorySelectorProps> = ({ options }) => {
    return (
      <div>
        <div className="grid grid-cols-3 sm:grid-cols-4 gap-4 mt-3">
          {options.map(({ value, icon }) => (
            <div
              key={value}
              className="flex flex-col items-center p-3 rounded-lg transition border border-gray-300"
            >
              <div className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-400">
                {icon || <Check size={24} className="text-gray-500" />}
              </div>
              <p className="text-sm mt-2">{value}</p>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const capitalize = (str: string) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };

  const calculateAge = (dateOfBirth: string): number => {
    const today = new Date(); 
    const birthDate = new Date(dateOfBirth); 
    let age = today.getFullYear() - birthDate.getFullYear(); 

    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();
    
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
  
    return age;
  };
  
  interface UserInfoProps {
    gender: 'male' | 'female' | 'other';
    occupation: 'work' | 'study';
  }
  
  const UserInfo: React.FC<UserInfoProps> = ({ gender, occupation }) => {
    return (
      <div className="flex flex-col">
        {/* Gender Section */}
        <div className="flex flex-row ml-5">
          {gender === 'male' && (
            <>
              <Male className="border rounded-full" />
              <p className="p-2 self-center">Male</p>
            </>
          )}
          {gender === 'female' && (
            <>
              <Female className="border rounded-full" />
              <p className="p-2 self-center">Female</p>
            </>
          )}
          {gender === 'other' && (
            <>
              <Other className="border rounded-full" />
              <p className="p-2 self-center">Other</p>
            </>
          )}
        </div>
  
        {/* Occupation Section */}
        <div className="flex flex-row ml-5">
          {occupation === 'work' ? (
            <>
              <Work className="border rounded-full" />
              <p className="p-2 self-center">Work</p>
            </>
          ) : (
            <>
              <Study className="border rounded-full" />
              <p className="p-2 self-center">Study</p>
            </>
          )}
        </div>
      </div>
    );
  };

  interface CategoryDisplayProps {
    title: string;
    items?: string[];
  }
  
  const CategoryDisplay: React.FC<CategoryDisplayProps> = ({ title, items = [] }) => {
    if (!items.length) return null;
  
    return (
      <div className="flex flex-col flex-wrap pl-5">
        <h6 className="text-md font-normal dark:text-white">{title}</h6>
        <div className="flex flex-row flex-wrap">
          {items.map((item, index) => (
            <span
              key={index}
              className="bg-[#FFC000] text-[#26355D] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300 mt-1 mb-1 w-fit"
            >
              {item}
            </span>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div>
      <DialogHeader>
        <VisuallyHidden.Root>
          <DialogTitle />
        </VisuallyHidden.Root>
      </DialogHeader>
      <div className="flex flex-col gap-4 items-center overflow-y-auto max-h-[90vh] scrollbar-hide">
          <PhotoCollage images={cardInfo.image_list} />
          <div className="flex flex-col w-full p-5">
            <div className="flex flex-col">
              <p> {cardInfo.city} , Region </p>
              <p> ${cardInfo.desired_rent}/month</p>
              <p> {cardInfo.surface_area} sqm<sup>2</sup></p>
            </div>

            <div className="flex flex-row justify-between pt-5"> 
              <div className="flex flex-col gap-2 w-2/3">
                <p className="text-xl font-bold">Description</p>
                <p>{cardInfo.room_description}</p>

                <p className="text-xl font-bold">Property Amenities</p>
                <CategorySelector
                  options={cardInfo.property_amenities.map((amenity) => ({
                    value: amenity,
                    icon: <Check size={24} className="text-gray-500" />,
                  }))}
                />
                
                <p className="text-xl font-bold">Room Amenities</p>
                <CategorySelector
                  options={cardInfo.amenities.map((amenity) => ({
                    value: amenity,
                    icon: <Check size={24} className="text-gray-500" />,
                  }))}
                />
                
                <p className="text-xl font-bold">Room Rules</p>
                <div className="grid grid-cols-3 sm:grid-cols-4 gap-4 mt-3">
                  <div className="flex flex-col items-center p-3 rounded-lg transition border border-gray-300">
                    <div className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-400">
                      {cardInfo.pets_allowed ? (
                        <Check size={24} className="text-green-500" />
                      ) : (
                        <Check size={24} className="text-red-500" />
                      )}
                    </div>
                    <p className="text-sm mt-2">Pets</p>
                  </div>
                  <div className="flex flex-col items-center p-3 rounded-lg transition border border-gray-300">
                    <div className="w-12 h-12 flex items-center justify-center rounded-full border border-gray-400">
                      {cardInfo.smoking_allowed ? (
                        <Check size={24} className="text-green-500" />
                      ) : (
                        <Check size={24} className="text-red-500" />
                      )}
                    </div>
                    <p className="text-sm mt-2">Smoking</p>
                  </div>
                </div>
              </div>

              {/* Owner Part */}
              <div className="flex flex-col gap-2 w-1/3 pl-2">
                <img
                  className="w-1/2 sm:w-full object-contain p-5 self-center"
                  src={user?.img_url}
                  // alt="Profile Image"
                  onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                    e.currentTarget.src = "default-profile.png";
                  }}
                />
                {user && <>
                  <div className="flex flex-row flex-wrap mt-2">
                  <UserInfo gender={user.gender as 'male' | 'female' | 'other'} occupation={user.occupation as 'work' | 'study'} />
                  </div>
                  <p className="font-normal text-md p-5"> {[capitalize(user.first_name), ", ",  calculateAge(user.date_of_birth)]}</p>
                </>}
                <CategoryDisplay title="Personality Traits" items={user?.trait_list} />
          <CategoryDisplay title="Lifestyle Preferences" items={user?.lifestyle_list} />
          <CategoryDisplay title="Hobbies" items={user?.hobbies_list} />
          <CategoryDisplay title="Sports & Fitness" items={user?.sports_list} />
          <CategoryDisplay title="Movies" items={user?.movies_list} />
          <CategoryDisplay title="Music" items={user?.music_list} />
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};
