import { Heart, UserIcon } from "lucide-react";
import { Dialog, DialogContent, DialogTrigger } from "../ui/dialog";
import { HouseCardInfo } from "../../../types/houseCardInfo";
import { HouseCard } from "./houseCard";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination } from "swiper/modules";
import { Key, useEffect, useState } from "react";
import { useGetSingleCandidateProfile } from "../../api/hooks/useCandidates";
import { CardInfo } from "../../../types/cardInfo";

export const HouseListCard = ({
  cardInfo,
  isFavourite,
  // favouriteClicked,
}: {
  cardInfo: HouseCardInfo;
  isFavourite: boolean;
  // favouriteClicked: (value: boolean, id: string) => void;
}) => {

  const images = cardInfo.image_list.length > 0 ? cardInfo.image_list : ["default-house.png"];
  console.log("Images:", images);
  console.log("Images lenbgth:", images.length);
  const [owner, setCandidateInfo] = useState<CardInfo>();
  const { data } = useGetSingleCandidateProfile({ user_id: cardInfo.owner });

  useEffect(() => {
    if (data) {
      setCandidateInfo(data);
    }
  }, [data]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <div className="rounded-md shadow-md flex flex-col gap-2 items-center w-fit hover:cursor-pointer gap-4 z-0">
          <div className="w-[350px] h-[200px] overflow-hidden border border-[#ddd] rounded-t-md">
            <Swiper
              navigation
              pagination
              modules={[Navigation, Pagination]} 
              className="h-full w-full relative group"
              style={{
                "--swiper-navigation-color": "#f85a20",
                "--swiper-pagination-color": "#f85a20",
              } as React.CSSProperties}
            >
              {images.map((img: string | undefined, index: Key | null | undefined) => (
                <SwiperSlide key={index}>
                  <img
                    className="w-full h-full object-cover"
                    src={img}
                    onError={(e) => (e.currentTarget.src = "default-house.png")}
                    alt={`House Images`}
                  />
                </SwiperSlide>
              ))}
              <style>
              {`
                .swiper-button-next,
                .swiper-button-prev {
                  opacity: 0;
                  transition: opacity 0.3s ease-in-out;
                }
                .group:hover .swiper-button-next,
                .group:hover .swiper-button-prev {
                  opacity: 1;
                }
              `}
            </style>
            </Swiper>
          </div>
          <div className="w-full flex flex-row justify-between p-4">
              <div className="flex flex-col gap-1">
                <p>{cardInfo.city}, Region</p>
                <p>${cardInfo.desired_rent} / month</p>
                <p>{cardInfo.surface_area} sqm<sup>2</sup></p>
              </div>
              <div className="flex flex-col gap-2">
              {isFavourite ? (<Heart className="fill-orange text-orange w-[2rem] h-[2rem]" />) : (<Heart className="text-orange w-[2rem] h-[2rem]"/>)}
                {owner?.img_url ? (
                  <img src={owner.img_url} alt="Owner" className="w-[2rem] h-[2rem] rounded-full" />
                ) : (
                  <UserIcon className="w-[2rem] h-[2rem]" />
                )}
              </div>
            </div>
        </div>
      </DialogTrigger>
      <DialogContent>
        <HouseCard cardInfo={cardInfo}/>
      </DialogContent>
    </Dialog>
  );
};
