import { create } from "zustand";
import { persist, createJSONStorage } from "zustand/middleware";
import { UserInfo } from "../../types/userInfo";

// Define the shape of your store's state
interface UserStore {
  userInfo: UserInfo | undefined;
  setUserInfo: (userInfo: UserInfo) => void;
}

// Create the Zustand store with proper typing
export const useUserStore = create<UserStore>()(
  persist(
    (set) => ({
      userInfo: undefined, // Default state
      setUserInfo: (userInfo: UserInfo) => set({ userInfo }),
    }),
    {
      name: "user", // Key for persisting state
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);
