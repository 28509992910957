import { useQuery } from "@tanstack/react-query";
import {
  getCandidateProfiles,
  getSingleCandidateProfile,
} from "../endpoints/candidates";

export const useGetCandidateProfiles = ({
  filter_value,
}: {
  filter_value: string;
}) => {
  return useQuery({
    queryKey: ["getCandidates", { filter_value }],
    queryFn: getCandidateProfiles,
    staleTime: Infinity,
    retry: true,
  });
};

export const useGetSingleCandidateProfile = ({
  user_id,
}: {
  user_id: string;
}) => {
  return useQuery({
    queryKey: ["getCandidate", { user_id }],
    queryFn: getSingleCandidateProfile,
    staleTime: 0,
    retry: true,
  });
};
