import React from 'react';

interface UserIconProps {
  imageUrl: string;
  altText?: string;
  width?: string;
  height?: string;
  borderRadius?: string;
}

export const UserIcon: React.FC<UserIconProps> = ({
  imageUrl,
  altText = "User Icon",
  width = "200px",
  height = "200px",
  borderRadius = "50%",
}) => {
  const containerStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: width,
    height: height,
    borderRadius: borderRadius,
    overflow: "hidden",
    border: "1px solid #ddd",
  };

  const imageStyle: React.CSSProperties = {
    width: "100%",
    height: "100%",
    objectFit: "cover",
  };

  return (
    <div style={containerStyle}>
      <img src={imageUrl} alt={altText} style={imageStyle} 
       onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
        e.currentTarget.src = "default-profile.png";
      }}/>
    </div>
  );
};
