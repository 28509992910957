import clsx from "clsx";
import { UserDetails } from "../../../types/userInfo";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { useGetUserInfo } from "../../api/hooks/user";
import { useState } from "react";
import { HouseInfo } from "../../../types/houseInfo";
import { useGetHouseInfo } from "../../api/hooks/useHouse";
import { Upload } from "../ui/upload";





const validationSchema = Yup.object().shape({
    surface_area: Yup.number()
      .when("add_house", {
        is: true,
        then: (schema) => schema.required("Surface area is required"),
      })
      .positive("Must be a positive number"),
    city: Yup.string().when("add_house", {
      is: true,
      then: (schema) => schema.required("City is required"),
    }),
    address_line: Yup.string().when("add_house", {
      is: true,
      then: (schema) => schema.required("Address is required"),
    }),
    // image_list: Yup.array().of(Yup.number()).optional(),
    desired_rent: Yup.number()
    .when("add_house", {
      is: true,
      then: (schema) => schema.required("Surface area is required"),
    })
  });

export const FormStepFour = ({
  handleSubmit,
  handleBack,
}: {
  handleSubmit: (values: HouseInfo | null) => void;
  handleBack: () => void;
}) => {
  const { data: userData, isLoading: userInfoLoading } = useGetUserInfo();
//   const {data: houseData, isLoading: houseInfoLoading} = useGetHouseInfo();
  const [addHouse, setAddHouse] = useState(false);

  const initialValues = {
    surface_area: 0,
    city: "",
    address_line: "",
    image_list: [] as File[],
    desired_rent:0,
  };

  return (
 <div className="flex flex-col gap-4 mx-auto w-8/12">
      {/* Question: Do you want to add a house? */}
      {!addHouse && (
        <div className="text-center p-4 bg-gray-100 rounded-lg">
          <p className="text-lg font-medium">Would you like to add a house?</p>
          <div className="flex justify-center gap-4 mt-3">
            <button
              onClick={() => setAddHouse(true)}
              className="bg-green-500 text-white px-6 py-2 rounded-lg"
            >
              Yes
            </button>
            <button
              onClick={() => handleSubmit(null)}
              className="bg-gray-500 text-white px-6 py-2 rounded-lg"
            >
              No, Finish Form
            </button>
          </div>
        </div>
      )}

      {/* House Form */}
      {addHouse && (
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ isSubmitting, isValid, setFieldValue }) => (
            <Form className="space-y-4 p-4">
              <div className="max-w-sm">
                <label htmlFor="surface_area" className="block font-medium">
                  Surface Area (in square meters)
                </label>
                <Field
                  name="surface_area"
                  type="number"
                  placeholder="Enter surface area..."
                  className="border p-2 rounded w-full"
                />
              </div>

              <div className="max-w-sm">
                <label htmlFor="city" className="block font-medium">
                  City
                </label>
                <Field
                  name="city"
                  placeholder="Enter city..."
                  className="border p-2 rounded w-full"
                />
              </div>

              <div className="max-w-sm">
                <label htmlFor="address_line" className="block font-medium">
                  Address
                </label>
                <Field
                  name="address_line"
                  placeholder="Enter house address..."
                  className="border p-2 rounded w-full"
                />
              </div>

              <div className="max-w-sm">
                <label htmlFor="desired_rent" className="block font-medium">
                  Desired rent (in euros)
                </label>
                <Field
                  name="desired_rent"
                  type="number"
                  placeholder="Enter desired rent..."
                  className="border p-2 rounded w-full"
                />
              </div>

              {/* Image Upload */}
               <div className=" w-full shadow-lg rounded-sm p-4 max-h-96 sm:w-[40%]">
                <div className="">
                    <p className="text-lg font-medium">Add a profile photo</p>
                    <Upload
                    maxFiles={10}
                    handleDrop={(files) => {
                        setFieldValue("image_list", [files]);
                    }}
                    />
                </div>
                </div>

              {/* Submit & Back Buttons */}
              <div className="flex p-4 justify-end gap-4">
                <button
                  type="button"
                  onClick={handleBack}
                  className={clsx(
                    "bg-gray-300 text-white px-8 py-2 rounded-lg text-lg cursor-pointer sm:px-16 sm:py-4"
                  )}
                >
                  Back
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting || !isValid}
                  className={clsx(
                    "bg-[#32936F] text-white px-8 py-2 rounded-lg text-lg cursor-pointer sm:px-16 sm:py-4",
                    (isSubmitting || !isValid) && "opacity-70"
                  )}
                >
                  Submit
                </button>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </div>
  );
};
