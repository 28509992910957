import { useMutation, useQuery } from "@tanstack/react-query";
import { HouseInfo } from "../../../types/houseInfo";
import { getHouseInfo, getHouses, updateHouse } from "../endpoints/house";

export const useUpdateHouse = () => {
    return useMutation({
      mutationFn: (payload: HouseInfo) => updateHouse(payload),
    });
};

export const useGetHouseInfo = ({
    house_id,
}: {
    house_id: string;
}) => {
    return useQuery({
        queryKey: ["getHouse", { house_id }],
        queryFn: getHouseInfo,
        staleTime: 0,
        retry: true,
    });
}

export const useGetHouses = () => { 
    return useQuery({
        queryKey: ["getHouses"],
        queryFn: getHouses,
        staleTime: 0,
        retry: true,
    });
}
