import { useEffect, useState } from "react";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { DialogHeader, DialogTitle } from "../ui/dialog";
import { Male } from "../../icons/male";
import { Female } from "../../icons/female";
import { Other } from "../../icons/other";
import { Work } from "../../icons/work";
import { Study } from "../../icons/study";
import { City } from "../../icons/city";
import { MyWallet } from "../../icons/myWallet";
import { Heart, MapPin, PencilRuler, Wallet } from "lucide-react";
import { useGetHouseInfo } from "../../api/hooks/useHouse";
import { HouseCardInfo } from "../../../types/houseCardInfo";

export const HouseCard = ({
  cardInfo,
}: {
  cardInfo: HouseCardInfo;
})  => {
  const [candidateInfo, setCandidateInfo] = useState<HouseCardInfo>();
  const { data } = useGetHouseInfo({house_id: cardInfo.house_id});

  useEffect(() => {
    if (data) {
      setCandidateInfo(data);
    }
  }, [data]);

  return (
    <div>
      <DialogHeader>
        <VisuallyHidden.Root>
          <DialogTitle />
        </VisuallyHidden.Root>
      </DialogHeader>
        <div className="flex flex-col gap-4 items-center overflow-y-auto max-h-[90vh] scrollbar-hide">
          {/* <UserIcon  imageUrl = {cardInfo.img_url}/> */}
              <img className="w-full object-cover p-5 max-h-[500px] rounded-[2rem]" src={cardInfo.image_list[0]} alt="Profile Image" onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                    e.currentTarget.src = "default-house.png";
                  }}/>
          <div className="flex flex-col w-full p-3">
          <div className="flex  items-center gap-2">
              <MapPin className="fill-white text-yellow" />
              <p>{cardInfo.city}</p>    
            </div>
            <div className="flex items-center gap-1">
                <PencilRuler className="fill-white text-yellow"></PencilRuler>
                <p>{cardInfo.surface_area} cm<sup>2</sup></p>
            </div>
            <div className="flex items-center gap-1">
              <Wallet className="fill-white text-yellow" />
              <p>{cardInfo.desired_rent}€</p>
            </div>
          </div>
          <div>
            {/* <p>Address: {cardInfo.address_line}</p> */}
          </div>
        </div>
    </div>
  );
};
