
import { HouseInfo } from "../../../types/houseInfo";
import { instance } from "../../config/intercepter";


export const updateHouse = async (payload: HouseInfo) => {
  const response = await instance.post(`/house/add`, payload);

  return response.data;
};

export const getHouseInfo = async ({
    queryKey,
}:{
    queryKey: [string, {house_id: string}];
}) => {
    const [_key, {house_id}] = queryKey;

    const response = await instance.get(`/house/get/${house_id}`);

    return response.data;
};

export const getHouses = async ({
    queryKey,
}:{
    queryKey: [string];
}) => {
    const response = await instance.get(`/house/get-all`);

    return response.data;
};