import { useEffect, useState } from "react";
import { CardInfo } from "../../../types/cardInfo";
import { useGetSingleCandidateProfile } from "../../api/hooks/useCandidates";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { DialogHeader, DialogTitle } from "../ui/dialog";
import { UserIcon } from "../../icons/userIcon";
import { BriefcaseBusiness, MapPin, Wallet } from "lucide-react";

export const RoomateCard = ({ cardInfo }: { cardInfo: CardInfo }) => {
  const [candidateInfo, setCandidateInfo] = useState<CardInfo>();
  const { data } = useGetSingleCandidateProfile({ user_id: cardInfo.id });

  useEffect(() => {
    if (data) {
      setCandidateInfo(data);
    }
  }, [data]);
  return (
    <div>
      <DialogHeader>
        <VisuallyHidden.Root>
          <DialogTitle />
        </VisuallyHidden.Root>
      </DialogHeader>
      <div className="flex flex-col gap-4 items-center">
        <UserIcon  imageUrl = {cardInfo.img_url}/>
        <p className="text-xl">
          {candidateInfo?.first_name} {candidateInfo?.last_name}
        </p>
        <div className="flex justify-between w-9/12">
          <div className="flex items-center gap-1">
            <MapPin className="fill-white text-yellow" />
            <p>{cardInfo.city_of_interest}</p>
          </div>
          <div className="flex items-center gap-1">
            <Wallet className="fill-white text-yellow" />
            <p>{cardInfo.budget_max}€</p>
          </div>
          <div className="flex items-center gap-1">
            <BriefcaseBusiness className="fill-white text-yellow" />
            <p>missing occupation</p>
          </div>
        </div>
        <div className="flex flex-col gap-2 justify-between w-9/12 rounded-2xl bg-[#F9F9F9] p-5">
          <p>{candidateInfo?.question_1_answer}</p>
          <p>{candidateInfo?.question_2_answer}</p>
          <p>{candidateInfo?.question_3_answer}</p>
        </div>
      </div>
    </div>
  );
};
