import { instance } from "../../config/intercepter";
import {PostS3Payload, UploadImageToS3} from "../../../types/payloadS3"
import axios from "axios";

export const registerImageAsDoc = async(payload: Partial<PostS3Payload>) => {
    const response = await instance.post('/s3/presigned-url-post', payload);
    console.log(response.data);
    return response.data;
};

export const uploadImage = async(payload: any) => {
    let data = payload.data
    let file_to_upload = payload.file_to_upload
    const fields = data.fields;
    console.log(fields);
    const formData = new FormData();
    console.log(payload.file_to_upload);
     for (const key in fields) {
        formData.append(key, fields[key]);
    }
    formData.append('file', file_to_upload);
    console.log(payload.url)
    const response = await axios.post(data.url, formData, {
            headers: { 'Content-Type': 'multipart/form-data' }, // Correct header
    });
    console.log(response.data);
    console.log(response)
    return response.data;
};