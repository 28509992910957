import { useMutation } from "@tanstack/react-query";
import { uploadImage, registerImageAsDoc } from "../endpoints/images";
import { instance } from "../../config/intercepter";
import {PostS3Payload, UploadImageToS3} from "../../../types/payloadS3"

export const useRegisterImageAsDoc = () => {
  return useMutation({
    mutationFn: (payload: Partial<PostS3Payload>) => registerImageAsDoc(payload),
  });
};

export const useUploadImage = () => {
  return useMutation({
    mutationFn: (payload: any) => uploadImage(payload),
  });
};