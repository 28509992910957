import { useEffect, useState } from "react";
import { Loader } from "../components/ui/loader";
import { useGetHouses } from "../api/hooks/useHouse";
import { HouseListCard } from "../components/houses/houseListCard";
import { HouseCardInfo } from "../../types/houseCardInfo";

export const FindHousePage = () => {
  const [profiles, setProfiles] = useState<HouseCardInfo[]>([]);
  const {data: housesData, isLoading: housesLoading} = useGetHouses();

  useEffect(() => {
    if (housesData) {
      setProfiles(housesData);
    } else {
      setProfiles([]);
    }
  }, [housesData]);

  return (
    <div className="flex flex-col pt-5 gap-4">
      <div className="text-2xl flex justify-center">
        Find your ideal &nbsp; <span className="text-[#F85A20]">Room</span>
        &nbsp; today
      </div>
      <div className="px-10 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3 xxl:grid-cols-4 gap-16 pb-10 ">
        {housesLoading && <Loader />}
        {profiles &&
          profiles.map((item) => (
            <HouseListCard
              key={item.house_id}
              cardInfo={item}
              isFavourite={false}
              // isFavourite={
              //   currentPairs!.find((liked) => item.id === liked) !== undefined
              // }
              // favouriteClicked={handleFavouriteClicked}
            />
          ))}
      </div>
    </div>
  );
};
