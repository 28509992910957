import React from "react";

interface ErrorModalProps {
  message: string;
  onClose: () => void;
}

const ErrorModal: React.FC<ErrorModalProps> = ({ message, onClose }) => {
  if (!message) return null;

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
    <div className="bg-white p-6 rounded-lg shadow-lg text-center w-80">
      <p className="text-lg font-medium text-[#f85a20]">{message}</p>
      <button
        onClick={onClose}
        className="mt-4 px-6 py-2 bg-[#f85a20] text-white rounded-full hover:bg-[#FFC000] transition"
      >
        OK
      </button>
    </div>
  </div>
);
};

export default ErrorModal;
