import { ErrorMessage, Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { Male } from "../../icons/male";
import { Female } from "../../icons/female";
import { Other } from "../../icons/other";
import { Work } from "../../icons/work";
import { Study } from "../../icons/study";
import clsx from "clsx";
import { UserDetails } from "../../../types/userInfo";
import PhoneInput from "react-phone-input-2";
import { Upload } from "../ui/upload";
import { useGetUserInfo } from "../../api/hooks/user";



interface FormValues {
  first_name: string;
  last_name: string;
  gender: string;
  occupation: string;
  date_of_birth: string;
  phone_number: string;
  profile_image: File | null;
}


const validationSchema = Yup.object({
  first_name: Yup.string().required("First name is required"),
  last_name: Yup.string().required("Last name is required"),
  gender: Yup.string().required("Gender is required"),
  occupation: Yup.string().required("Occupation is required"),
  phone_number: Yup.string()
    .required("Phone number is required")
    .test(
      "is-valid-phone",
      "Phone number must have between 10 and 15 digits",
      (value: any) =>
        value &&
        value.replace(/\D/g, "").length >= 10 &&
        value.replace(/\D/g, "").length <= 15,
    ),
  date_of_birth: Yup.date()
    .max(
      new Date(new Date().setFullYear(new Date().getFullYear() - 18)),
      "You must be at least 18 years old",
    )
    .required("Date of birth is required"),
});

export const FormStepOne = ({
  handleSubmit,
}: {
  handleSubmit: (values: Partial<UserDetails>) => void;
}) => {
  // Validation Schema
  const { data: userData, isLoading: userInfoLoading } = useGetUserInfo();
  const initialValues: FormValues = {
    first_name: userData?.first_name || "",
    last_name: userData?.last_name || "",
    gender: userData?.gender || "",
    occupation: userData?.occupation || "",
    date_of_birth: userData?.date_of_birth || "",
    phone_number: userData?.phone_number || "",
    profile_image: userData?.img_url ? new File([], userData.img_url) : null,
  };
  return (
    <div className="flex flex-col gap-4 mx-auto w-8/12">
      <p className="text-slate-600 text-xl text-center">
        Tell us a bit about yourself to find your ideal roommate
      </p>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, setFieldValue, values, isValid }) => {
          return (
            <Form className="space-y-4">
              <div className="flex flex-row gap-16 flex-wrap">
                <div className="flex flex-col">
                  {/* My Profile */}
                  <div className="flex flex-col gap-4 w-1/2">
                    <p className="text-lg font-medium">My Profile</p>
                    <p>* Required fields</p>
                  </div>
                  {/* First Name */}
                  <div className="flex flex-col ">
                    <p>What's your Name? *</p>
                    <div className="flex gap-2 flex flex-raw max-w-sm">
                      <div className="w-full">
                        <Field
                          placeholder="First Name"
                          type="text"
                          name="first_name"
                          id="first_name"
                          className="border p-2 rounded w-full"
                        />
                        <ErrorMessage
                          name="first_name"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                      <div className="w-full">
                        <Field
                          placeholder="Last Name"
                          type="text"
                          name="last_name"
                          id="last_name"
                          className="border p-2 rounded w-full"
                        />
                        <ErrorMessage
                          name="last_name"
                          component="div"
                          className="text-red-500 text-sm"
                        />
                      </div>
                    </div>

                      
                    <div className="w-full">
                      <p>What's your date of birth? *</p>
                      <Field
                        type="date"
                        name="date_of_birth"
                        id="date_of_birth"
                        className="border p-2 rounded w-full"
                      />
                      <ErrorMessage
                        name="date_of_birth"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="w-full">
                      <p>What's your phone number? *</p>
                      <PhoneInput
                        country={"gr"} // Default country
                        value={values.phone_number} // Current value of the phone field
                        onChange={(phone) => setFieldValue("phone_number", phone)} // Update Formik value
                        specialLabel=""
                        inputProps={{
                          name: "phone_number",
                          className: "border p-2 rounded w-full",
                        }}
                      />
                      <ErrorMessage
                        name="phone_number"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                    {/* Gender */}
                    <div className="flex flex-col gap-2">
                      <p>Choose your gender? *</p>
                      <div className="flex gap-4">
                        <button
                          type="button"
                          onClick={() => {
                            setFieldValue("gender", "male");
                          }}
                        >
                          <Male
                            className={
                              values.gender === "male"
                                ? "border-orange border rounded-full"
                                : ""
                            }
                          />
                          <p className="text-xs pt-1">Male</p>
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setFieldValue("gender", "female");
                          }}
                        >
                          <Female
                            className={
                              values.gender === "female"
                                ? "border-orange border rounded-full"
                                : ""
                            }
                          />
                          <p className="text-xs pt-1">Female</p>
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setFieldValue("gender", "other");
                          }}
                        >
                          <Other
                            className={
                              values.gender === "other"
                                ? "border-orange border rounded-full"
                                : ""
                            }
                          />
                          <p className="text-xs pt-1">Other</p>
                        </button>
                      </div>
                      <ErrorMessage
                        name="gender"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>

                    <div className="flex flex-col gap-2">
                      <p>Choose your Occupation? *</p>
                      <div className="flex gap-4">
                        <button
                          type="button"
                          onClick={() => {
                            setFieldValue("occupation", "study");
                          }}
                        >
                          <Study
                            className={
                              values.occupation === "study"
                                ? "border-orange border rounded-full"
                                : ""
                            }
                          />
                          <p className="text-xs pt-1">Studying</p>
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setFieldValue("occupation", "work");
                          }}
                        >
                          <Work
                            className={
                              values.occupation === "work"
                                ? "border-orange border rounded-full"
                                : ""
                            }
                          />
                          <p className="text-xs pt-1">Working</p>
                        </button>
                      </div>
                      <ErrorMessage
                        name="occupation"
                        component="div"
                        className="text-red-500 text-sm"
                      />
                    </div>
                  </div>
                </div>
                
                <div className=" w-full shadow-lg rounded-sm p-4 max-h-96 sm:w-[40%]">
                  <div className="">
                    <p className="text-lg font-medium">Add a profile photo *</p>
                    <Upload
                      maxFiles={1}
                      handleDrop={(file) => {
                        // console.log("EDO FILE: ", file[0]);
                        setFieldValue("profile_image", file[0]);
                      }}
                    />
                  </div>
                </div>
              </div>

              {/* Submit Button */}
              <div className="flex p-4 w-full justify-end gap-4">
                <button
                  type="submit"
                  disabled={isSubmitting || !isValid}
                  className={clsx(
                  "bg-[#32936F] text-white px-16 py-4 rounded-lg text-lg cursor-pointer",
                  (isSubmitting || !isValid) && "opacity-70",
                  )}
                >
                  Next
                </button>
              </div>
            </Form>
          );
        }}
      </Formik>
      </div>
  );
};
