import clsx from "clsx";
import { UserDetails } from "../../../types/userInfo";
import { Formik, Form, Field, FieldArray, FormikProps  } from "formik";
import * as Yup from "yup";
import { useGetUserInfo } from "../../api/hooks/user";

const validationSchema = Yup.object().shape({
  introduce_yourself: Yup.string(),
  hobbies: Yup.string(),
  music_preferred: Yup.string(),
  movies_preferred: Yup.string(),
  trait_list: Yup.array().of(Yup.string()),
  lifestyle_list: Yup.array().of(Yup.string()),
  sports_list: Yup.array().of(Yup.string()),
  hobbies_list: Yup.array().of(Yup.string()),
  music_list: Yup.array().of(Yup.string()),
  movies_list: Yup.array().of(Yup.string()),
});

const userOptions = {
  personalityTraits: [
    "Active", "Calm", "Caring", "Cheerful", "Cooperative", "Creative", "Determined",
    "Easygoing", "Empathetic", "Enthusiastic", "Flexible", "Friendly", "Fun", "Honest",
    "Joker", "Optimistic", "Organized", "Passionate", "Practical", "Proactive",
    "Rational", "Relaxed", "Sociable", "Tolerant"
  ],
  lifestylePreferences: [
    "Early bird", "Night owl", "Workaholic", "Homebird", "Family person", 
    "Partier", "Health enthusiast", "Vegan", "Vegetarian"
  ],
  interests: [
    "Art enthusiast", "Book lover", "Cinema goer", "Culture enthusiast", "Fashionista",
    "Theatre fan", "Painter", "Photographer", "Avid music lover", "Singer", "Dancer",
    "Foodie", "Chef", "Gamer", "Tech lover", "Adventurer", "Traveller", "Pet lover"
  ],
  sportsAndFitness: [
    "American football", "Baseball", "Basketball", "Cricket", "Football", "Hockey", "Rugby",
    "Athletics", "Badminton", "Bowls", "Boxing", "Climbing", "Cycling", "Golf",
    "Bodyboarding", "Canoeing", "Diving", "Rowing", "Surfing", "Swimming",
    "Skiing", "Snowboarding", "Go-karting", "Motorcycling", "Skateboarding", "Skating",
    "Gym", "Hiking", "Martial arts", "Pole dancing", "Running", "Squash", "Yoga"
  ],
  musicPreferences: [
    "Alternative", "Asian pop", "Blues", "Classical", "Country", "Dance", "Electronic / EDM",
    "Folk", "Funk", "Gospel", "Hip-Hop / Rap", "Indie", "Instrumental", "Jazz", "Latin",
    "Lounge", "Metal", "New Age", "Opera", "Pop", "Punk", "R&B / Soul", "Reggae", "Rock",
    "Ska", "Soul"
  ],
  moviePreferences: [
    "Action", "Adventure", "Animation", "Comedy", "Crime", "Documentary", "Drama",
    "Fantasy", "Historical", "Horror", "Mystery", "Political", "Romance", "Saga",
    "Satire", "Sci-Fi", "Thriller", "Western"
  ]
};

export const FormStepThree = ({
  handleSubmit,
  handleBack,
}: {
  handleSubmit: (values: Partial<UserDetails>) => void;
  handleBack: () => void;
}) => {
  const { data: userData, isLoading: userInfoLoading } = useGetUserInfo();
  const initialValues = {
    introduce_yourself: userData?.introduce_yourself || "",
    hobbies: userData?.hobbies || "",
    music_preferred: userData?.music_preferred || "",
    movies_preferred: userData?.movies_preferred || "",
    trait_list: userData?.trait_list || [],
    lifestyle_list: userData?.lifestyle_list || [],
    hobbies_list: userData?.hobbies_list || [],
    sports_list: userData?.sports_list || [],
    music_list: userData?.music_list || [],
    movies_list: userData?.movies_list || [],
  };

  interface CategorySelectorProps {
    name: string;
    label: string;
    options: string[];
  }
  
  const CategorySelector: React.FC<CategorySelectorProps> = ({ name, label, options }) => (
    <div>
      <label className="block font-medium">{label}</label>
      <FieldArray name={name}>
        {({ push, remove, form }) => (
          <div className="flex flex-wrap gap-2 mt-2">
            {options.map((option) => {
              // console.log("edooo: ", form.values[name])
              const isSelected = form.values[name]?.includes(option);
              return (
                <button
                  key={option}
                  type="button"
                  className={clsx(
                    "px-4 py-2 rounded-full border transition",
                    isSelected
                      ? "bg-[#f85a20] text-white border-[#f85a20]"
                      : "bg-gray-200 text-black border-gray-400"
                  )}
                  onClick={() =>
                    isSelected
                      ? remove(form.values[name].indexOf(option))
                      : push(option)
                  }
                >
                  {option}
                </button>
              );
            })}
          </div>
        )}
      </FieldArray>
      <Field name={name}>
        {({ meta }: { meta: { touched: boolean; error?: string } }) =>
          meta.touched && meta.error && <p className="text-red-500">{meta.error}</p>
        }
      </Field>
    </div>
  );

  return (
    <div className="flex flex-col gap-4 mx-auto w-8/12">
      <p className="text-slate-600 text-xl text-center">
        Tell us a bit about yourself to find your ideal roommate
      </p>
      <div className="flex flex-col">
        <p className="text-lg font-medium">My Hobbies</p>
      </div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, isValid }) => (
          <Form className="space-y-4 p-4">
            <div>
              <label htmlFor="introduce_yourself" className="block font-medium">
                Introduce Yourself
              </label>
              <Field
                name="introduce_yourself"
                as="textarea"
                placeholder="Write something about yourself..."
                className="border p-2 rounded w-full"
              />
            </div>

            <CategorySelector name="trait_list" label="Select Your Personality Traits" options={userOptions.personalityTraits} />
            <CategorySelector name="lifestyle_list" label="Select Your Lifestyle Preferences" options={userOptions.lifestylePreferences} />
            <CategorySelector name="hobbies_list" label="Select Your Interests" options={userOptions.interests} />
            <CategorySelector name="sports_list" label="Select Your Sports & Fitness Activities" options={userOptions.sportsAndFitness} />
            <CategorySelector name="music_list" label="Select Your Music Genres" options={userOptions.musicPreferences} />
            <CategorySelector name="movies_list" label="Select Your Movie Genres" options={userOptions.moviePreferences} />
  
            <div>
              <label htmlFor="hobbies" className="block font-medium">
                Share more details about your interest!
              </label>
              <Field
                name="hobbies"
                placeholder="Add some details"
                className="border p-2 rounded w-full"
              />
            </div>

            {/* Submit Button */}
            <div className="flex p-4 justify-end gap-4">
              <button
                type="button"
                onClick={handleBack}
                className={clsx(
                "bg-gray-300 text-white px-8 py-2 rounded-lg text-lg cursor-pointer sm:px-16 sm:py-4"
                )}
              >
                Back
              </button>
              <button
                type="submit"
                disabled={isSubmitting || !isValid}
                className={clsx(
                "bg-[#32936F] text-white px-8 py-2 rounded-lg text-lg cursor-pointer sm:px-16 sm:py-4",
                (isSubmitting || !isValid) && "opacity-70"
                )}
              >
                Next
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
