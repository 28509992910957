// axiosInstance.js
import axios from "axios";
import { clearStorage } from "../utils/helper";

// Axios instance
export const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.defaults.headers.common["Content-Type"] = "application/json";

// // Request Interceptor to Attach the Token
instance.interceptors.request.use(function (config) {
  const accessToken = localStorage.getItem("bearer_token");
  console.log(accessToken);

  config.headers.Authorization = config.headers.Authorization
    ? config.headers.Authorization
    : `Bearer ${accessToken}`;
  return config;
});

// // Response Interceptor to Handle Errors
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.log(error);
    const status = error.response?.status;

    if (status === 401 || status === 403) {
      console.log(`Unauthenticated for this request`)
    }

    return Promise.reject(error);
  },
);

// export default instance;
