import { useAuth0 } from "@auth0/auth0-react";
import { Bell, MessageSquareText } from "lucide-react";
import { useNavigate } from "react-router-dom";
import { UserPopover } from "../components/user/userPopover";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "../components/ui/sheet";
import { useUserStore } from "../store/userStore";

export const Header = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth0();
  const { userInfo } = useUserStore();

  const canBeDisplayed = () => {
    return (
      isAuthenticated && !!userInfo && userInfo.status !== "USER_DOES_NOT_EXIST"
    );
  };

  return (
    <div className="flex justify-between items-center justify-center shadow-md px-4 py-2 sticky top-0 bg-white">
      <div className="flex gap-4 items-center justify-center">
        <button onClick={() => navigate("/login")}>
          <img className="w-[80px] sm:w-[120px]" src="\Logo.png" alt="logo" />
        </button>
        {canBeDisplayed() && (
          <button
            className="text-[15px] sm:text-[30px] font-medium text-[#6550EC]"
            onClick={() => {
              navigate("/find-matches");
            }}
          >
            Roommates
          </button>
        )}
        {canBeDisplayed() && (<button className="text-[15px] sm:text-[30px] font-medium text-[#6550EC]"
            onClick={() => {
              navigate("/find-house");
            }}>
          Rooms
        </button>
      )}
      </div>
      {canBeDisplayed() && (
        <div className="flex gap-4">
          <Sheet>
            <SheetTrigger>
              <div className="rounded-full bg-[#8833FF1A] p-2 h-8 w-8 sm:h-10 sm:w-10 flex items-center justify-center">
                <Bell className="fill-[#8833FF]" size={20} />
              </div>
            </SheetTrigger>
            <SheetContent>
              <SheetHeader>
                <SheetTitle>Notifications</SheetTitle>
              </SheetHeader>
            </SheetContent>
          </Sheet>
          <button
            onClick={() => {
              navigate("/chat");
            }}
          >
            <MessageSquareText
              className="fill-[#FFC000] text-white"
              size={30}
            />
          </button>
          <UserPopover />
        </div>
      )}
    </div>
  );
};
