import { Route, Routes } from "react-router-dom";
import { Layout } from "./layouts/layout";
import { FindMatchesPage } from "./pages/findMatchesPage";
import { ProtectedRoute } from "./utils/protectedRoute";
import { UserFormPage } from "./pages/userFormPage";
import { DashboardPage } from "./pages/dashboardPage";
import { LoginPage } from "./pages/loginPage";
import { ChatPage } from "./pages/chatPage";
import { FindHousePage } from "./pages/findHousePage";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<LoginPage />} />
        <Route
          path="/my-profile"
          element={<ProtectedRoute Component={<DashboardPage />} />}
        />
        <Route
          path="/edit"
          element={<ProtectedRoute Component={<UserFormPage />} />}
        />
        <Route
          path="/roomates"
          element={<ProtectedRoute Component={<FindMatchesPage />} />}
        />
        <Route
          path="/inbox"
          element={<ProtectedRoute Component={<ChatPage />} />}
        />
        <Route
          path ="/rooms"
          element={<ProtectedRoute Component={<FindHousePage />} />}
        />
        {/*
        <Route path="/user-profile" />
        <Route path="/prices" /> */}
      </Route>
    </Routes>
  );
}

export default App;
