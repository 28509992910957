import { instance } from "../../config/intercepter";
import {PostS3Payload, UploadImageToS3} from "../../../types/payloadS3"
import axios from "axios";

export const registerImageAsDoc = async(payload: Partial<PostS3Payload>) => {
    const response = await instance.post('/s3/presigned-url-post', payload);
    console.log(response.data);
    return response.data;
};

export const uploadImage = async (payload: any) => {
    try {
        let data = payload.data;
        let file_to_upload = payload.file_to_upload;
        const fields = data.fields;

        const formData = new FormData();
        for (const key in fields) {
            formData.append(key, fields[key]);
        }
        formData.append("file", file_to_upload);

        const response = await axios.post(data.url, formData, {
            headers: { "Content-Type": "multipart/form-data" },
        });

        if (response.status !== 200 && response.status !== 204) {
            throw new Error("Upload failed with status: " + response.status);
        }

        console.log("Upload successful", response.data);
        return response.data;
    } catch (error) {
        console.error("Error uploading image:", error);
        throw error; 
    }
};
