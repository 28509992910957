
export const LoginPage = () => {
  return (
    <>
    <section  className="bg-center bg-no-repeat bg-cover bg-[url('./rb_55204.png')]">
        <div className="px-4 mx-auto w-full text-center py-24 lg:py-56">
            <h1 className="mb-4 text-4xl font-extrabold tracking-tight leading-none text-[#6550ec] md:text-5xl lg:text-6xl">
                Find your ideal <span className="bg-[#f85a20] bg-clip-text text-transparent"> Roommate </span>
            </h1>
            <h1 className="text-4xl font-extrabold tracking-tight leading-none text-[#6550ec] md:text-5xl lg:text-6xl">
                one <span className="text-[#FFFFFF]"> Swipe </span> at a time.
            </h1>
            <div className="flex flex-col items-center space-y-4 m-[2rem] sm:flex-row sm:justify-center sm:space-y-0 ">
                <a href="/" className="inline-flex justify-center items-center py-3 px-5 text-base font-xlarge text-center text-white rounded-full bg-[#f85a20] hover:bg-[#6550ec] max-w-[fit-content]">
                    Get Started
                </a> 
            </div>
        </div>
    </section>

    <section className="px-5 py-14 bg-gradient-to-r from-[#f7f9fb] to-[#ffffff] text-center rounded-[12px] shadow-md max-w-[800px] mx-auto my-10 flex flex-col">
        <h2 className="text-4xl font-normal text-gray-800 mb-5">What We Do</h2>
        <p className="text-lg text-gray-600 leading-relaxed max-w-[600px] mx-auto px-2">
        Meetie is a roommate matching platform, that helps individuals find their ideal roommate and limit their biggest expense. Housing!</p>
        <p className="text-lg text-gray-600 leading-relaxed max-w-[600px] mx-auto px-2"> Unlike traditional listing services, we use advanced algorithms to pair users based on compatibility and preferences.
        </p>
    </section>

    <section className="bg-gray-100 px-6 py-12 text-center">
        <h2 className="text-4xl font-normal text-gray-800 mb-8">How It Works</h2>
        <div className="flex flex-wrap justify-center gap-6">
            {/* Step 1 */}
            <div className="w-full sm:w-[30%] p-5 bg-gradient-to-r from-[#f7f9fb] to-white rounded-lg shadow-md text-center">
            <img
                src="create_profile_icon.png"
                alt="Step 1 Icon"
                className="w-[60px] h-[60px] object-cover mx-auto mb-4"
            />
            <h3 className="text-2xl font-semibold text-gray-700 mb-2">Create a Profile</h3>
            <p className="text-gray-600 text-base">
                Answer a few questions to let us know your lifestyle and roommate preferences.
            </p>
            </div>

            {/* Step 2 */}
            <div className="w-full sm:w-[30%] p-5 bg-gradient-to-r from-[#f7f9fb] to-white rounded-lg shadow-md text-center">
            <img
                src="discover.png"
                alt="Step 2 Icon"
                className="w-[60px] h-[60px] object-cover mx-auto mb-4"
            />
            <h3 className="text-2xl font-semibold text-gray-700 mb-2">Discover Matches</h3>
            <p className="text-gray-600 text-base">
                Get matched with potential roommates who complement your habits and values.
            </p>
            </div>

            {/* Step 3 */}
            <div className="w-full sm:w-[30%] p-5 bg-gradient-to-r from-[#f7f9fb] to-white rounded-lg shadow-md text-center">
            <img
                src="Check_circle.png"
                alt="Step 3 Icon"
                className="w-[60px] h-[60px] object-cover mx-auto mb-4"
            />
            <h3 className="text-2xl font-semibold text-gray-700 mb-2">Find Your Ideal Roommate</h3>
            <p className="text-gray-600 text-base">
                Connect and chat with your matches to find your perfect living situation.
            </p>
            </div>
        </div>
    </section>

    <section className="py-12 text-center">
        <h2 className="text-4xl font-normal text-gray-800 mb-8">Meet the Team</h2>
        <div className="flex flex-wrap justify-center gap-16">
            {/* Team Member 1 */}
            <div className="w-[16rem] bg-gradient-to-r from-[#f7f9fb] to-white p-5 rounded-lg shadow-md text-center">
            <img
                src="profile-dimitris.jpg"
                alt="Team Member 1"
                className="w-[100px] h-[100px] rounded-full mx-auto mb-4 object-cover object-top"
            />
            <h3 className="text-2xl font-semibold text-gray-800 mb-1">Dimitris Thodis</h3>
            <p className="text-gray-600 text-base">Founder & CEO</p>
            </div>

            {/* Team Member 2 */}
            <div className="w-[16rem] bg-gradient-to-r from-[#f7f9fb] to-white p-5 rounded-lg shadow-md text-center">
            <img
                src="profile-john.jpg"
                alt="Team Member 2"
                className="w-[100px] h-[100px] rounded-full mx-auto mb-4 object-cover object-top"
            />
            <h3 className="text-2xl font-semibold text-gray-800 mb-1">Giannis Voulgaridis</h3>
            <p className="text-gray-600 text-base">Co-Founder & CTO testing changes</p>
            </div>

            {/* Team Member 3 */}
            <div className="w-[16rem] bg-gradient-to-r from-[#f7f9fb] to-white p-5 rounded-lg shadow-md text-center">
            <img
                src="profile-george.jpg"
                alt="Team Member 3"
                className="w-[100px] h-[100px] rounded-full mx-auto mb-4 object-cover object-top" 
            />
            <h3 className="text-2xl font-semibold text-gray-800 mb-1">George Papadoulis</h3>
            <p className="text-gray-600 text-base">Co-Founder</p>
            </div>
        </div>
    </section>

    <section className="bg-gray-100 text-center py-20 relative overflow-hidden">
        <div className="animate-bounceFade">
            <h2 className="text-5xl font-normal mb-4 text-transparent bg-clip-text bg-gradient-to-r from-[#FFC000] via-[#6550ec] to-[#f85a20]">
            Coming Soon
            </h2>
            <p className="text-xl  text-transparent bg-clip-text bg-gradient-to-r from-[#FFC000] via-[#6550ec] to-[#f85a20] max-w-xl mx-auto leading-relaxed">
            New features and exciting updates are on the way! Stay tuned.
            </p>
        </div>

        {/* Custom Animation */}
        <style>
            {`
            @keyframes bounceFade {
                0%, 100% {
                opacity: 0.5;
                }
                25% {
                opacity: 0.75;
                }
                50% {
                opacity: 1;
                }
                75% {
                opacity: 0.75;
                }
            }
            .animate-bounceFade {
                animation: bounceFade 2s ease-in-out infinite;
            }
            `}
        </style>
    </section>

    <section className="p-2 bg-[#26355D] text-center text-white">
    <div className="flex flex-col w-full">
        <div className="flex flex-col sm:flex-row w-full items-center justify-between flex-wrap">

            <div className="flex flex-row justify-center items-center w-full sm:w-1/3">
                <img src="Meetie_Logo(nobackground).png" alt="Footer Logo" className="w-[12rem] object-contain"></img>
            </div>
            
            <div className="w-full sm:w-1/3 flex flex-col items-center justify-center">
                <p>© 2024 Meetie. All Rights Reserved.</p>  
                <p>welcome@meetiego.gr</p>
            </div>
                
            <div className="flex flex-col items-center justify-center pt-2 w-full  sm:w-1/3 ">
                <div className="flex flex-row gap-2 flex-wrap items-center justify-center">
                    <a href="https://www.linkedin.com/company/meetiego/" className="w-24 h-24">
                        <img src="icons8-linkedin-400.png" alt="LinkedIn Icon" className="w-full h-full"></img>
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=61571914931043" className="w-24 h-24">
                        <img src="icons8-facebook-400.png" alt="Facebook Icon" className="w-full h-full"></img>
                    </a>
                    <a href="https://www.instagram.com/meetie.gr/" className="w-24 h-24">
                        <img src="icons8-instagram-400.png" alt="Instagram Icon" className="w-full h-full"></img>
                    </a>                    
                </div>
            </div>
        </div>

            <div className="flex flex-row items-center justify-center pl-2 gap-4 flex-wrap">
                <p>Powered by</p>
                        <img src="OrangeGrovelogo(no background).png" alt="Footer Logo" className="w-[9rem] h-24 object-contain rounded-full"></img>
                        <img src="EIT DIgitalLogo(no background).png" alt="Footer Logo" className="w-[9-rem] h-24 object-contain rounded-full"></img>
                        <img src="eu_logo.png" alt="Footer Logo" className="w-[9rem] h-24 object-contain rounded-md"></img>
                        <img src="foundation_logo.png" alt="Footer Logo" className="w-12 h-12 object-contain rounded-md"></img>
            </div>
    </div>
    </section>

</>)
};
