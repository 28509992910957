import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import { Loader } from "../components/ui/loader";
import { useGetUserOnboardingStatus } from "../api/hooks/user";
import { useUserStore } from "../store/userStore";
import { UserFormPage } from "../pages/userFormPage";
import { useNavigate } from "react-router-dom";

//logic here if it is the first login or not
export const ProtectedRoute = ({ Component }: any) => {
  const [firstLogin, setFirstLogin] = useState<boolean | null>(null);
  const navigate = useNavigate();

  const {
    isAuthenticated,
    isLoading,
    loginWithRedirect,
    getAccessTokenSilently,
  } = useAuth0();

  const { setUserInfo } = useUserStore();
  const { data } = useGetUserOnboardingStatus(isAuthenticated);

  // If authenticated, save the token to localStorage
  const saveTokenToLocalStorage = async () => {
    try {
      const accessToken = await getAccessTokenSilently();
      localStorage.setItem("bearer_token", accessToken);
    } catch (error) {
      console.error("Error getting access token:", error);
    }
  };

  useEffect(() => {
    if (isLoading) return;
    if (!isAuthenticated) {
      loginWithRedirect();
    } else {
      saveTokenToLocalStorage();
    }
  }, [isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently]);

  useEffect(() => {
    if (data) {
      setUserInfo(data);
      if (data.status !== "USER_DOES_NOT_EXIST") {
        setFirstLogin(false);
      } else {
        setFirstLogin(true);
        navigate("/edit");
      }
    }
  }, [data]);

  console.log(`Is user authenticated: ${isAuthenticated}`);

  if (isLoading || firstLogin === null) {
    return (
      <div className="w-[100vw] flex justify-center items-center">
        <Loader />
      </div>
    );
  }

  return isAuthenticated ? (
    firstLogin && Component !== <UserFormPage /> ? (
      <UserFormPage />
    ) : (
      Component
    )
  ) : null; // Render nothing if authentication is pending
};
