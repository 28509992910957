import { instance } from "../../config/intercepter";

export const getCandidateProfiles = async ({
  queryKey,
}: {
  queryKey: [string, { filter_value: string }];
}) => {
  const [_key, { filter_value }] = queryKey;

  const response = await instance.get(
    `/user/get-candidate-profiles?filter_value=${filter_value}`,
  );

  return response.data;
};

export const getSingleCandidateProfile = async ({
  queryKey,
}: {
  queryKey: [string, { user_id: string }];
}) => {
  const [_key, { user_id }] = queryKey;

  const response = await instance.get(`/user/get-candidate-profile/${user_id}`);

  return response.data;
};
