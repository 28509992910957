import { Check } from "lucide-react";

export const Stepper = ({
  totalSteps,
  currentStep,
}: {
  totalSteps: number;
  currentStep: number;
}) => {
  return (
    <div className="flex items-center justify-center ">
      {Array.from({ length: totalSteps }, (_, index) => {
        const step = index + 1;
        const isActive = step === currentStep;
        const isCompleted = step < currentStep;

        return (
          <div key={step} className="flex items-center">
            <div
              className={`flex items-center justify-center w-10 h-10 rounded-full border-2 ${
                isCompleted
                  ? "border-orange text-white"
                  : isActive
                    ? "border-orange bg-orange text-white"
                    : "border-gray-300 bg-white text-gray-500"
              }`}
            >
              {isCompleted ? <Check className="text-orange" /> : step}
            </div>

            {/* Connecting line */}
            {step < totalSteps && (
              <div
                className={`h-1 w-16 flex-1 ${
                  isCompleted ? "bg-orange" : "bg-gray-300"
                }`}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};
