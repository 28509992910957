import { useEffect, useState } from "react";
import { CardInfo } from "../../../types/cardInfo";
import { useGetSingleCandidateProfile } from "../../api/hooks/useCandidates";
import * as VisuallyHidden from "@radix-ui/react-visually-hidden";
import { DialogHeader, DialogTitle } from "../ui/dialog";
import { Male } from "../../icons/male";
import { Female } from "../../icons/female";
import { Other } from "../../icons/other";
import { Work } from "../../icons/work";
import { Study } from "../../icons/study";
import { City } from "../../icons/city";
import { MyWallet } from "../../icons/myWallet";
import { Heart } from "lucide-react";

export const RoomateCard = ({
  cardInfo,
  isFavourite,
  favouriteClicked,
}: {
  cardInfo: CardInfo;
  isFavourite: boolean;
  favouriteClicked: (value: boolean, id: string) => void;
})  => {
  const [candidateInfo, setCandidateInfo] = useState<CardInfo>();
  const { data } = useGetSingleCandidateProfile({ user_id: cardInfo.id });

  useEffect(() => {
    if (data) {
      setCandidateInfo(data);
    }
  }, [data]);

  const calculateAge = (dateOfBirth: string | undefined): number => {
    const today = new Date(); 
    const birthDate = new Date(dateOfBirth as string); 
    let age = today.getFullYear() - birthDate.getFullYear(); 

    const monthDiff = today.getMonth() - birthDate.getMonth();
    const dayDiff = today.getDate() - birthDate.getDate();
    
    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
      age--;
    }
  
    return age;
  };

  const formatDate = (isoString: string | undefined): string => {
    const date = new Date(isoString as string);
    const day = date.getDate();
    const month = date.toLocaleString('en-US', { month: 'long' }); 
    const year = date.getFullYear();
  
    return `${day} ${month}`;
  };

  interface CategoryProps {
    title: string;
    items?: string[];
  }
  
  const Category: React.FC<CategoryProps> = ({ title, items = [] }) => {
    if (!items.length) return null; // Hide if empty
  
    return (
      <div className="flex flex-col gap-2 pb-3">
        <p className="text-xl">{title}</p>
        <div className="flex flex-row flex-wrap">
          {items.map((item, index) => (
            <span
              key={index}
              className="bg-[#FFC000] text-[#26355D] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300 mt-1 mb-1 w-fit"
            >
              {item}
            </span>
          ))}
        </div>
      </div>
    );
  };
  

  return (
    <div>
      <DialogHeader>
        <VisuallyHidden.Root>
          <DialogTitle />
        </VisuallyHidden.Root>
      </DialogHeader>
        <div className="flex flex-col gap-4 items-center overflow-y-auto max-h-[90vh] scrollbar-hide">
          {/* <UserIcon  imageUrl = {cardInfo.img_url}/> */}
              <img className="w-full object-cover p-5 max-h-[500px] rounded-[2rem]" src={cardInfo.img_url} alt="Profile Image" 
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                      e.currentTarget.src = "default-profile.png";
                    }}/>
          <div className="flex flex-col w-full p-3">
            <div className="flex flex-row items-center justify-between w-full pl-5 pt-5 pr-5 flex-wrap">  
              <div className="flex flex-row items-center">
                <div className="flex flex-row gap-4 items-center">
                    <p className="text-2xl">
                      {candidateInfo?.first_name}, {calculateAge(candidateInfo?.date_of_birth)}
                    </p>
                    {candidateInfo?.gender === 'male' ? (
                        <div className="flex flex-row">
                          <Male className={ candidateInfo?.gender === "male" ? "border rounded-full" : ""}/>
                        </div>
                      ) : candidateInfo?.gender === 'female' ? (
                        <div className="flex flex-row">
                          <Female className={ candidateInfo?.gender === "female" ? "border rounded-full" : ""}/>
                        </div>
                      ) : (
                        <div className="flex flex-row">
                          <Other className={ candidateInfo?.gender === "other" ? "border rounded-full" : ""}/>
                        </div>
                      )}

                </div>

                <div className="flex flex-row gap-4 items-center"> {/* Occupation container */}
                  {candidateInfo?.occupation === "work"? (
                    <div className="flex flex-row ml-5">
                      <Work className={ candidateInfo?.occupation === "work" ? "border rounded-full" : "" }/>
                      {/* <p className="text-xl">Work</p> */}
                    </div>
                  ) : (
                    <div className="flex flex-row items-center">
                      <Study className={ candidateInfo?.occupation === "study" ? "border rounded-full" : "" }/>
                      {/* <p className="text-xl">Study</p> */}
                    </div>
                  )}
                </div> {/* End of Occupation */}
              </div>
                <button
                onClick={() => favouriteClicked(!isFavourite, cardInfo.id)}
                className="flex items-center justify-center"
                >
                <Heart className={`w-20 h-20 ${isFavourite ? "text-orange fill-orange" : ""}`} strokeWidth={isFavourite ? 2 : 1} />
                </button>
            </div>

            <div className="flex flex-row items-start w-full p-5">
              <span className="bg-[#FFC000] text-[#26355D] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300 mt-1 mb-1">
                {candidateInfo?.allow_smoking === true ? ("Smoker") : ("Non-Smoker")}
              </span>
              <span className="bg-[#FFC000] text-[#26355D] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300 mt-1 mb-1">
                {candidateInfo?.allow_pet === true ? ("Allow Pets") : ("Not Allow Pets")}
              </span>
            </div>

            <div className="flex flex-col w-full p-5 rounded-2xl p-5"> {/* Where to live info */}
              <p className="text-xl pb-3"> Looking for a room in</p> 
              <div className="flex flex-col gap-4">
                <div className="flex flex-row gap-4">  {/* City and Budget Container */}
                  <div className="flex flex-row items-center gap-2">
                    <City className={""}></City> <p>{cardInfo.city_of_interest} </p>
                  </div>
                  <div className="flex flex-row items-center gap-2">
                  {/* <MapPin className="fill-white text-yellow w-[40px] h-[40px]" /> */}
                  <div className="flex flex-row items-center flex-wrap">
                  {candidateInfo?.regions && (() => {
                        // Convert the input array into strings
                        const inputArray = Object.values(candidateInfo?.regions || []);
                        const regionsArray = [];
                        let currentString = "";

                        inputArray.forEach((char) => {
                          if (char === ',') {
                            if (currentString.length > 0) {
                              regionsArray.push(currentString); // Add the complete region
                              currentString = ""; // Reset the string
                            }
                          } else {
                            currentString += char; // Add character to the current string
                          }
                        });

                        // Add the last string if it exists
                        if (currentString.length > 0) {
                          regionsArray.push(currentString);
                        }

                        // Render the processed regions
                        return regionsArray.map((region, index) => (
                          <span
                            key={index}
                            className="bg-[#FFC000] text-[#26355D] text-xs font-medium me-2 px-2.5 py-0.5 rounded-full dark:bg-blue-900 dark:text-blue-300 mt-1 mb-1 w-fit h-fit"
                          >
                            {region.trim()}
                          </span>
                        ));
                      })()}
                    </div>
                     
                  </div>
                </div> {/* End of City and Budget Container */}
                <div className="flex flex-row gap-2"> {/* Regions Container */}
                <MyWallet className={""}></MyWallet> <p>{cardInfo.budget_max}€</p>  
                </div> {/* End of Regions Container */}
              </div>
            </div>

            {/* About Me container */}
            <div className="p-5 w-full">
              <div className="flex flex-col gap-2 w-full rounded-2xl ">
                <div className="flex flex-col gap-2 pb-3 ">
                  <p className="text-xl"> About Me</p>
                  <p className="">{candidateInfo?.introduce_yourself}</p>
                </div>
                <Category title="Personality Traits" items={candidateInfo?.trait_list} />
                <Category title="Lifestyle Preferences" items={candidateInfo?.lifestyle_list} />
                <Category title="Hobbies" items={candidateInfo?.hobbies_list} />
                <Category title="Sports & Fitness" items={candidateInfo?.sports_list} />
                <Category title="Music" items={candidateInfo?.music_list} />
                <Category title="Movies" items={candidateInfo?.movies_list} />
                {candidateInfo?.hobbies && (
            <div className="flex flex-col flex-wrap ">
              <p className="text-xl">More</p>
              <p className="font-normal text-gray-700 dark:text-gray-400">{candidateInfo.hobbies}</p>
            </div>
          )}      
              </div>
            </div> 
        </div>
      </div>
    </div>
  );
};
