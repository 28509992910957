import { Bell, HousePlus, LogOut, Settings, User } from "lucide-react";
import { useGetUserInfo } from "../../api/hooks/user";
import { Popover, PopoverTrigger, PopoverContent } from "../ui/popover";
import { Separator } from "../ui/separator";
import { useAuth0 } from "@auth0/auth0-react";
import { clearStorage } from "../../utils/helper";
import { useNavigate } from "react-router-dom";

export const UserPopover = () => {
  const { data: userInfo } = useGetUserInfo();
  const { logout } = useAuth0();
  const navigate = useNavigate();

  const handleProfileClick: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.preventDefault(); // Optional: prevent any default behavior
    navigate("/");
  };

  const handleLogout = () => {
    clearStorage();
    logout({
      logoutParams: {
        returnTo: `${window.location.origin}/login`, // Redirect URL after logout
        client_id: process.env.REACT_APP_CLIENT_ID, // Auth0 client ID
      },
    });
  };

  return (
    <Popover>
      <PopoverTrigger>
        {/* <div className="rounded-full bg-black h-10 w-10 hover:cursor-pointer" /> */}
        <img 
          className="rounded-full h-10 w-10 hover:cursor-pointer" 
          src={userInfo?.img_url} 
          alt = "user image"
          onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
            e.currentTarget.src = "default-profile.png";
          }}
        />
      </PopoverTrigger>
      <PopoverContent className="p-0">
        <div className="flex flex-col gap-1">
          <div className="flex gap-3 p-2">
            {/* <div className="rounded-full bg-black h-10 w-10" /> */}
            <img className="rounded-full h-10 w-10" 
              src={userInfo?.img_url} 
              alt = "user image"
              onError={(e: React.SyntheticEvent<HTMLImageElement, Event>) => {
                e.currentTarget.src = "default-profile.png";
              }}
            />
            <div className="flex flex-col justify-center">
              <p className="text-sm">
                {userInfo?.first_name} {userInfo?.last_name}
              </p>{" "}
              <p className="text-xs text-slay-300">{userInfo?.email}</p>
            </div>
          </div>
          <Separator />
          <div className="flex gap-2 items-center p-2 hover:bg-slate-100">
            <HousePlus className="text-orange" size={18} />
            <p className="text-sm">Try Premium Now!</p>
          </div>
          <Separator />
          <div
            onClick={handleProfileClick}
            className="flex gap-2 items-center p-2 hover:bg-slate-100"
          >
            <User size={18} />
            <p className="text-sm hover:cursor-pointer">My Profile</p>
          </div>
          <div className="flex gap-2 items-center p-2 hover:bg-slate-100">
            <Settings size={18} />
            <p className="text-sm">Settings</p>
          </div>
          <div className="flex gap-2 items-center p-2 hover:bg-slate-100">
            <Bell size={18} />
            <p className="text-sm">Notifications</p>
          </div>
          <button
            className="flex gap-2 items-center hover:bg-slate-100 p-2"
            onClick={handleLogout}
          >
            <LogOut size={18} />
            <p className="text-sm">Log Out</p>
          </button>
        </div>
      </PopoverContent>
    </Popover>
  );
};
