import { useMutation, useQuery } from "@tanstack/react-query";
import {
  createUserPair,
  deleteUserPair,
  getCurrentPairs,
  getUserInfo,
  getUserOnboardingStatus,
  updateUserProfile,
} from "../endpoints/user";
import { CardInfo } from "../../../types/cardInfo";
import { UserDetails, UserInfo } from "../../../types/userInfo";

export const useGetCurrentPairs = () => {
  return useQuery<string[]>({
    queryKey: ["getPairs"],
    queryFn: getCurrentPairs,
    staleTime: Infinity,
    retry: true,
  });
};

export const useDeleteUserPair = () => {
  return useMutation({
    mutationFn: (payload: string) => deleteUserPair(payload),
  });
};

export const useCreateUserPair = () => {
  return useMutation({
    mutationFn: (payload: string) => createUserPair(payload),
  });
};

export const useGetUserInfo = () => {
  return useQuery<CardInfo>({
    queryKey: ["userInfo"],
    queryFn: getUserInfo,
    staleTime: Infinity,
    retry: true,
  });
};

export const useGetUserOnboardingStatus = (isAuthenticated: boolean) => {
  return useQuery<UserInfo>({
    queryKey: ["getOnboardingStatus"],
    queryFn: getUserOnboardingStatus,
    staleTime: Infinity,
    retry: true,
    enabled: isAuthenticated,
  });
};

export const useUpdateUser = () => {
  return useMutation({
    mutationFn: (payload: Partial<UserDetails>) => updateUserProfile(payload),
  });
};
