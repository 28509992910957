import { SetStateAction, useEffect, useState } from "react";
import { Stepper } from "../components/ui/stepper";
import { useUserStore } from "../store/userStore";
import { FormStepOne } from "../components/form/formStepOne";
import { UserDetails } from "../../types/userInfo";
import { useUpdateUser } from "../api/hooks/user";
import { useNavigate } from "react-router-dom";
import { FormStepTwo } from "../components/form/formStepTwo";
import { FormStepThree } from "../components/form/formStepThree";
import { useUploadImage, useRegisterImageAsDoc } from "../api/hooks/useImages";
import { FormStepFour } from "../components/form/formStepFour";
import { HouseInfo } from "../../types/houseInfo";
import { useUpdateHouse } from "../api/hooks/useHouse";
import ErrorModal from "../components/ui/errorModal";

export const UserFormPage = () => {
  const { userInfo } = useUserStore();
  const [currentStep, setCurrentStep] = useState(1);
  const [presignedData, setPresignedData] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");

  const { mutate } = useUpdateUser();
  const {mutate: updateHouse} = useUpdateHouse();
  const { mutate: uploadImage } = useUploadImage();
  const {mutate: getPresignedUrl} = useRegisterImageAsDoc();
  const { mutateAsync: getPresignedUrlAsync } = useRegisterImageAsDoc(); // Convert to async mutation

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo) {
      if (userInfo.onboarding_step === null) {
        setCurrentStep(1);
      } else {
        setCurrentStep(userInfo.onboarding_step + 2);
      }
    }
  }, [userInfo]);

  const handleError = (message: SetStateAction<string>) => {
    setErrorMessage(message); 
  };

  const handleCloseError = () => {
    setErrorMessage("");
    window.location.reload(); 
  };

  const handleSubmit = (values: Partial<UserDetails>) => {
    // console.log("Values to submit: " , values)
    let finalValues = { ...values, onboarding_step: currentStep - 1 };

    if (currentStep === 1) {
      getPresignedUrl(
          { filename: values.profile_image?.name || "", doc_type: "PRF" },
          {
            onSuccess: async (data) => {
                try {
                    await uploadImage({ data, file_to_upload: values.profile_image });

                    console.log("Image uploaded successfully");
                    finalValues.profile_image = data["local_file_id"];

                    // Proceed with form submission only if image upload succeeds
                    mutate(finalValues, {
                        onSuccess: () => {
                            // console.log(finalValues);
                            window.location.reload();
                        },
                        onError: (error) => {
                            console.error("Form submission failed:", error);
                            handleError("An error occurred while submitting the form. Please try again.");
                        },
                    });
                } catch (error) {
                    console.error("Image upload failed:", error);
                    handleError("Failed to upload image. Please try again.");
                }
            },
            onError: (error) => {
                console.error("Failed to get presigned URL:", error);
                handleError("An error occurred while getting the upload URL. Please try again.");
            },
        }
    );
    } else {
        mutate(finalValues, {
            onSuccess: () => {
                // console.log(finalValues);
                // if (currentStep !== 3) window.location.reload();
                // else navigate("/");
                window.location.reload();
            },
            onError: (error) => {
                console.error("Form submission error:", error);
                handleError("An error occurred. Please try again.");
            },
        });
    }
  } 

  const handleHouseSubmit = async (values: HouseInfo | null) => {
    console.log("Submitting values:", values);

    if (!values) {
      mutate({ onboarding_step: currentStep - 1 }, { onSuccess: () => navigate("/my-profile") });
      return;
    }
    
    let finalValues = { ...values, onboarding_step: currentStep - 1 , allow_pets: false, allow_smoking: false};
    finalValues.image_list = [];

    try {
      const uploadImagePromise = async (image: File): Promise<string> => {
        const data = await getPresignedUrlAsync({ filename: image.name || "", doc_type: "PRF" });

        console.log("🔗 Got presigned URL:", data);

        await uploadImage({ data, file_to_upload: image });
        console.log("✅ Image uploaded successfully:", data["local_file_id"]);

        return data["local_file_id"];
      };

      //Ensure all images upload before proceeding
      finalValues.image_list = await Promise.all(values.image_list.map((img: File) => uploadImagePromise(img)));

      //extract flat rules


      console.log("[FINAL VALUES]:", finalValues);
      // Submit final values after all images are uploaded
      await updateHouse(finalValues, {
        onSuccess: () => {console.log("🏠 House updated successfully!"); 
                          navigate("/my-profile")
                        },
        onError: (error) => {
          console.error("❌ Form submission error:", error);
          handleError("An error occurred. Please try again.");
        },
      });
    } catch (error) {
      console.error("❌ Error in image upload process:", error);
      handleError("Failed to upload all images. Please try again.");
    }
  };

  const handleBack = () => {
    if (currentStep > 1) {
      const previousStep = currentStep - 1;
      setCurrentStep(previousStep);
      mutate({ onboarding_step: previousStep - 1 }, {
        onSuccess: (data) => {
          console.log(`Moved back to step ${previousStep}`);
        },
      });
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="text-2xl flex justify-center pt-4">
        Create your &nbsp; <span className="text-[#F85A20]">Meetie</span>
        &nbsp; profile
      </div>
      {/* <p className="text-sm text-center self-center text-[#FFC000] w-fit">
        Private info will not be shared!
      </p> */}
      <Stepper totalSteps={4} currentStep={currentStep} onStepClick={setCurrentStep}/>

      {currentStep === 1 && <FormStepOne handleSubmit={handleSubmit} />}
      {currentStep === 2 && <FormStepTwo handleSubmit={handleSubmit} handleBack={handleBack} />}
      {currentStep === 3 && <FormStepThree handleSubmit={handleSubmit} handleBack={handleBack} />}
      {currentStep === 4 && <FormStepFour handleSubmit={handleHouseSubmit} handleBack={handleBack} />}

      {/* Error Modal */}
      <ErrorModal message={errorMessage} onClose={handleCloseError} />
    </div>
  );
};

export type UserInfo = {
  onboarding_step: number | null;
  status: string;
};