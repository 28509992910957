import { UserDetails } from "../../../types/userInfo";
import { instance } from "../../config/intercepter";

export const getCurrentPairs = async () => {
  const response = await instance.get(`/user/get-pairs`);

  return response.data;
};

export const deleteUserPair = async (user_id: string) => {
  const payload = {
    user_id: user_id,
  };

  const response = await instance.delete(`/user/delete-pair`, {
    data: payload,
  });
  return response.data;
};

export const createUserPair = async (user_id: string) => {
  const payload = {
    user_id: user_id,
  };

  const response = await instance.post(`/user/post-pair`, payload);
  return response.data;
};

export const getUserInfo = async () => {
  const response = await instance.get(`/user/get-user`);

  return response.data;
};

export const getUserOnboardingStatus = async () => {
  const response = await instance.get(`/user/user-onboarding-status`);

  return response.data;
};

export const updateUserProfile = async (payload: Partial<UserDetails>) => {
  console.log("Payload: ",payload)
  const response = await instance.patch(`/user/user-profile`, payload);

  return response.data;
};
