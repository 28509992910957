import { useEffect, useState } from "react";
import { Stepper } from "../components/ui/stepper";
import { useUserStore } from "../store/userStore";
import { FormStepOne } from "../components/form/formStepOne";
import { UserDetails } from "../../types/userInfo";
import { useUpdateUser } from "../api/hooks/user";
import { useNavigate } from "react-router-dom";
import { FormStepTwo } from "../components/form/formStepTwo";
import { FormStepThree } from "../components/form/formStepThree";
import { useUploadImage, useRegisterImageAsDoc } from "../api/hooks/useImages";
import { useAuth0 } from "@auth0/auth0-react";
import { FormStepFour } from "../components/form/formStepFour";
import { HouseInfo } from "../../types/houseInfo";
import { useUpdateHouse } from "../api/hooks/useHouse";

export const UserFormPage = () => {
  const { userInfo } = useUserStore();
  const [currentStep, setCurrentStep] = useState(1);
  const [presignedData, setPresignedData] = useState(null);

  const { mutate } = useUpdateUser();
  const {mutate: updateHouse} = useUpdateHouse();
  const { mutate: uploadImage } = useUploadImage();
  const {mutate: getPresignedUrl} = useRegisterImageAsDoc()

  const navigate = useNavigate();

  useEffect(() => {
    if (userInfo) {
      if (userInfo.onboarding_step === null) {
        setCurrentStep(1);
      } else {
        setCurrentStep(userInfo.onboarding_step + 2);
      }
    }
  }, [userInfo]);

  const handleSubmit = (values: Partial<UserDetails>) => {
    let finalValues = { ...values, onboarding_step: currentStep - 1 };

    if (currentStep === 1) {
      getPresignedUrl(
          { filename: values.profile_image?.name || "", doc_type: "PRF" },
          {
              onSuccess: async (data) => {
                  try {
                      await uploadImage({ data, file_to_upload: values.profile_image });

                      console.log("Image uploaded successfully");
                      finalValues.profile_image = data["local_file_id"];

                      // Proceed with form submission only if image upload succeeds
                      mutate(finalValues, {
                          onSuccess: () => {
                              console.log(finalValues);
                              window.location.reload();
                          },
                          onError: (error) => {
                              console.error("Form submission failed:", error);
                              alert("An error occurred while submitting the form. Please try again.");
                          },
                      });
                  } catch (error) {
                      console.error("Image upload failed:", error);
                      alert("Failed to upload image. Please try again.");
                  }
              },
              onError: (error) => {
                  console.error("Failed to get presigned URL:", error);
                  alert("An error occurred while getting the upload URL. Please try again.");
              },
          }
      );
  } else {
      mutate(finalValues, {
          onSuccess: () => {
              console.log(finalValues);
              // if (currentStep !== 3) window.location.reload();
              // else navigate("/");
              window.location.reload();
          },
          onError: (error) => {
              console.error("Form submission error:", error);
              alert("An error occurred. Please try again.");
          },
      });
  }
}

const handleHouseSubmit = (values: HouseInfo | null) => {
  console.log(values);
  if (values == null) {
    navigate("/");
  } else {
    let finalValues = { ...values, onboarding_step: currentStep - 2 };
    console.log("List:", values.image_list)
    for (const image of values.image_list) {
      console.log("hey")
      getPresignedUrl(
        { filename: image.name || "", doc_type: "PRF" },
        {
          onSuccess: async (data) => {
            try {
              await uploadImage({ data, file_to_upload: image });

              console.log("Image uploaded successfully");
              finalValues.image_list = [data["local_file_id"]];
              updateHouse(finalValues, {
                onSuccess: () => {
                    console.log(finalValues);
                    // if (currentStep !== 3) window.location.reload();
                    // else navigate("/");
                    navigate("/");
                },
                onError: (error) => {
                    console.error("Form submission error:", error);
                    alert("An error occurred. Please try again.");
                },
              });
            } catch (error) {
              console.error("Image upload failed:", error);
              alert("Failed to upload image. Please try again.");
            }
          },
          onError: (error) => {
            console.error("Failed to get presigned URL:", error);
            alert("An error occurred while getting the upload URL. Please try again.");
          },
        }
      );
    }
    console.log(finalValues)
    console.log(finalValues.image_list)
    console.log(finalValues.image_list[0])
  
  }
}


  const handleBack = () => {
    if (currentStep > 1) {
      const previousStep = currentStep - 1;
      setCurrentStep(previousStep);
      mutate({ onboarding_step: previousStep - 1 }, {
        onSuccess: (data) => {
          console.log(`Moved back to step ${previousStep}`);
        },
      });
    }
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="text-2xl flex justify-center pt-4">
        Create your &nbsp; <span className="text-[#F85A20]">Meetie</span>
        &nbsp; profile
      </div>
      <Stepper totalSteps={4} currentStep={currentStep} />
      {currentStep === 1 && <FormStepOne handleSubmit={handleSubmit} />}
      {currentStep === 2 && <FormStepTwo handleSubmit={handleSubmit} handleBack={handleBack} />}
      {currentStep === 3 && <FormStepThree handleSubmit={handleSubmit} handleBack={handleBack} />}
      {currentStep === 4 && <FormStepFour handleSubmit={handleHouseSubmit} handleBack={handleBack} />}
    </div>
  );
};

export type UserInfo = {
  onboarding_step: number | null;
  status: string;
};
//
// export type UserDetails = {
//   first_name: string;
//   last_name: string;
//   email: string;
//   gender: string;
//   occupation: string;
//   date_of_birth: string;
//   profile_image: any;
//   city_of_interest: string;
//   regions: string[] | null | undefined; // Allow null or undefined
//   budget_max: number;
//   phone_number: string;
//   preferred_move_in_date: string | null | undefined; // Allow null or undefined
//   is_smoker: boolean | null | undefined; // Allow null or undefined
//   has_pets: boolean | null | undefined; // Allow null or undefined
//   cleaning_habits: string | null | undefined; // Allow null or undefined
//   introduce_yourself: string;
//   hobbies: string;
//   music_preferred: string;
//   movies_preferred: string;
//   onboarding_step: number;
// };